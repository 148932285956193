import React from 'react';
import classNames from 'classnames';

import LogoFull from '~/assets/images/nnLogo.svg';
import LogoBasic from '~/assets/images/logo.svg';
import LogoFlat from '~/assets/icons/flat-nn.svg';
import LogoFlatWhite from '~/assets/icons/flat-nn-white.svg';


export interface LogoProps {
    /** css classes */
    className?: string;
    /**
     * The optional variant of the logo, for example `full` or `basic` or `flat`
     *
     * @default "full"
     **/
    variant?: 'full' | 'basic' | 'flat' | 'flatWhite';
    /** Logo width in pixels */
    width?: number;
}

const defaultLogoFullWidth = 220;
const defaultLogoBasicWidth = 100;
const defaultLogoFlatWidth = 120;
const defaultLogoFlatWhiteWidth = 110;

export const Logo: React.FC<LogoProps> = (props) => {
    const {
        className,
        width,
        variant = 'full',
    } = props;

    const iconClassNames = classNames(
        className,
    );

    return (
        <div className={iconClassNames}>
            {variant === 'full' && (
                <LogoFull
                    width={width ?? defaultLogoFullWidth}
                    height="auto"
                />
            )}
            {variant === 'basic' && (
                <LogoBasic
                    width={width ?? defaultLogoBasicWidth}
                    height="auto"
                />
            )}
            {variant === 'flat' && (
                <LogoFlat
                    width={width ?? defaultLogoFlatWidth}
                    height="auto"
                />
            )}
            {variant === 'flatWhite' && (
                <LogoFlatWhite
                    width={width ?? defaultLogoFlatWhiteWidth}
                    height="auto"
                />
            )}
        </div>
    );
};
