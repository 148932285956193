export enum PictographType {
    Account = 'PictographType.Account',
    Bike = 'PictographType.Bike',
    Boat = 'PictographType.Boat',
    Buffer = 'PictographType.Buffer',
    Car = 'PictographType.Car',
    Children = 'PictographType.Children',
    Clothing = 'PictographType.Clothing',
    Cloudy = 'PictographType.Cloudy',
    Co2 = 'PictographType.Co2',
    DamageInsurance = 'PictographType.DamageInsurance',
    DamageInsuranceBusiness = 'PictographType.DamageInsuranceBusiness',
    Dentist = 'PictographType.Dentist',
    FamilyExpansion = 'PictographType.FamilyExpansion',
    Funeral = 'PictographType.Funeral',
    Furniture1 = 'PictographType.Furniture1',
    Furniture2 = 'PictographType.Furniture2',
    Furniture3 = 'PictographType.Furniture3',
    Furniture4 = 'PictographType.Furniture4',
    Fysiotherapie = 'PictographType.Fysiotherapie',
    Glasses = 'PictographType.Glasses',
    Health = 'PictographType.Health',
    HealthInsurance = 'PictographType.HealthInsurance',
    HealthInsuranceCollective = 'PictographType.HealthInsuranceCollective',
    HealthList = 'PictographType.HealthList',
    Hobby1 = 'PictographType.Hobby1',
    Hobby2 = 'PictographType.Hobby2',
    Home = 'PictographType.Home',
    IncomeInsuranceCollective = 'PictographType.IncomeInsuranceCollective',
    InformalCare = 'PictographType.InformalCare',
    Invest1 = 'PictographType.Invest1',
    Kids = 'PictographType.Kids',
    Lightning = 'PictographType.Lightning',
    Loan = 'PictographType.Loan',
    Mortgage = 'PictographType.Mortgage',
    NaturalHealingMethods = 'PictographType.NaturalHealingMethods',
    Orthodontics = 'PictographType.Orthodontics',
    Overview = 'PictographType.Overview',
    Partner = 'PictographType.Partner',
    Party1 = 'PictographType.Party1',
    Party2 = 'PictographType.Party2',
    Pension = 'PictographType.Pension',
    Pension1 = 'PictographType.Pension1',
    Pension2 = 'PictographType.Pension2',
    Products = 'PictographType.Products',
    Refinancing = 'PictographType.Refinancing',
    Repair1 = 'PictographType.Repair1',
    Repair2 = 'PictographType.Repair2',
    Restaurant = 'PictographType.Restaurant',
    Risico = 'PictographType.Risico',
    Saving = 'PictographType.Saving',
    Service = 'PictographType.Service',
    Settings = 'PictographType.Settings',
    Sickness = 'PictographType.Sickness',
    SicknessAbsence = 'PictographType.SicknessAbsence',
    SicknessAbsenceFrequency = 'PictographType.SicknessAbsenceFrequency',
    Sock = 'PictographType.Sock',
    Sport1 = 'PictographType.Sport1',
    Sport2 = 'PictographType.Sport2',
    Sport3 = 'PictographType.Sport3',
    SportsAllowance = 'PictographType.SportsAllowance',
    Study2 = 'PictographType.Study2',
    Study1 = 'PictographType.Study1',
    Sun = 'PictographType.Sun',
    Target = 'PictographType.Target',
    Trailer = 'PictographType.Trailer',
    Trash = 'PictographType.Trash',
    Vacation = 'PictographType.Vacation',
    Vacation3 = 'PictographType.Vacation3',
    Vacation2 = 'PictographType.Vacation2',
    Vacation4 = 'PictographType.Vacation4',
    Water = 'PictographType.Water',
    WorkLonger = 'PictographType.WorkLonger',
    Files = 'PictographType.Files',
    Human = 'PictographType.Human',
    Medicare = 'PictographType.Medicare',
    Money = 'PictographType.Money',
    Objects = 'PictographType.Objects',
    Travel = 'PictographType.Travel',
    Umbrella = 'PictographType.Umbrella',
    Weather = 'PictographType.Weather',
    IdDocumentFront = 'PictographType.IdDocumentFront',
    IdDocumentRear = 'PictographType.IdDocumentRear',
    DocumentDetailed = 'PictographType.DocumentDetailed',
    RoomPicDetailed = 'PictographType.RoomPicDetailed',
    RoomPicCorners = 'PictographType.RoomPicCorners',
    HotLead = 'PictographType.HotLead',
    ColdLead = 'PictographType.ColdLead',
    ColdLead2 = 'PictographType.ColdLead2',
    ColdLead3 = 'PictographType.ColdLead3',
    SafeWheel = 'PictographType.SafeWheel',
    CancerRibbon = 'PictographType.CancerRibbon',
    Cookies = 'PictographType.Cookies',
    Grave = 'PictographType.Grave',
    Needle = 'PictographType.Needle',
    Thermometer = 'PictographType.Thermometer',
    Genetics = 'PictographType.Genetics',
    WheelChair = 'PictographType.WheelChair',
    Recovery = 'PictographType.Recovery',
    Crutch = 'PictographType.Crutch',
    Xray = 'PictographType.Xray',
    Pill = 'PictographType.Pill',
    Familly = 'PictographType.Familly',
    EmployeeUmbrella = 'PictographType.EmployeeUmbrella',
}

