/* eslint-disable import/no-extraneous-dependencies */
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export interface Props {
  children: Date;
}

export function formatTime(date: Date): string {
  return dayjs(date).format('H:mm');
}

export const FormatTime: React.FC<Props> = ({
  children,
}: Props) => <>{formatTime(children)}</>;
