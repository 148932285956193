import { IconMode, IconSize } from './Icon.types';
import {
    CIRCULAR_ICON_IMG_SIZE_VALUE, ICON_IMG_SIZE_VALUE,
} from './Icon.constants';
import { IconTypeAll } from './IconAll.set';

export const getAlternativeIconText = (type: IconTypeAll): string => {
    return `${type.toString()} icon`;
};

interface GetIconSizeParams {
    size?: IconSize;
    isCircular?: boolean;
}

export const getIconSize = (params: GetIconSizeParams): number => {
    const {
        size = IconSize.md,
        isCircular = false,
    } = params;

    return isCircular ? CIRCULAR_ICON_IMG_SIZE_VALUE[size] : ICON_IMG_SIZE_VALUE[size];
};

interface EstablishIconModeParams {
    isDisabled?: boolean;
    isInvalid?: boolean;
    isSuccess?: boolean;
}

export const establishIconMode = (params: EstablishIconModeParams): IconMode => {
    const {
        isDisabled = false,
        isInvalid = false,
        isSuccess = false,
    } = params;

    if (isDisabled) {
        return IconMode.disabled;
    }

    if (isInvalid) {
        return IconMode.error;
    }

    if (isSuccess) {
        return IconMode.success;
    }

    return IconMode.default;
};
