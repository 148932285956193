import styled, { css } from 'styled-components';
import { primaryColors } from '../../colors';

export type Types = 'primary' | 'secondary' | 'tertiary';
export type Sizes = 's' | 'm' | 'l';

export interface ButtonBaseProps {
  $type?: Types;
  $size?: Sizes;
  disabled?: boolean;
}

const hover = css`
  &:hover,
  &:active,
  &:focus {
    color: white;
    background: ${primaryColors.orangeDark};
    box-shadow: 0px 5px 20px rgba(240, 128, 0, 0.148805);
  }
`;

export const ButtonBase = styled.button<ButtonBaseProps>`
  font-family: NNDagnyDisplay;
  font-size: 18px;
  border-radius: 3px;
  box-sizing: border-box;
  border: 1px solid transparent;
  transition: background-color 100ms, border 100ms, color 100ms;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  ${({ $type, disabled }) => ($type === 'secondary' && disabled
    ? css`
          border: 1px solid ${primaryColors.greyLight};
          color: ${primaryColors.greyLight};
        `
    : '')}

  ${({ $type, disabled }) => ($type === 'secondary' && !disabled
    ? css`
          background-color: white;
          border: 1px solid ${primaryColors.orangeMedium};
          color: ${primaryColors.orangeMedium};
          ${hover}
        `
    : '')}

  ${({ $type, disabled }) => ($type === 'tertiary' && disabled
    ? css`
          background: transparent;
          color: ${primaryColors.greyLight};
        `
    : '')}

  ${({ $type, disabled }) => ($type === 'tertiary' && !disabled
    ? css`
          background: transparent;
          color: ${primaryColors.orangeMedium};

          &:hover,
          &:active,
          &:focus {
            border: 1px solid ${primaryColors.orangeMedium};
            filter: drop-shadow(0px 5px 20px rgba(240, 128, 0, 0.148805));
          }
        `
    : '')}

  ${({ $type, disabled }) => ($type === 'primary' && disabled
    ? css`
          background-color: ${primaryColors.greyWhite};
          color: ${primaryColors.greyLight};
        `
    : '')}

  ${({ $type, disabled }) => ($type === 'primary' && !disabled
    ? css`
          color: white;
          background-color: ${primaryColors.orangeMedium};
          ${hover}
        `
    : '')}
`;
