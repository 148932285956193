import React from 'react';
import { Autocomplete } from '../Autocomplete/Autocomplete';
import { SVGButton } from '../Button';
import { Pictogram } from '../Pictogram/Pictogram';

export const Search: React.FC<React.ComponentProps<typeof Autocomplete>> = ({
  onChange,
  value,
  label,
  required,
  pattern,
  name,
  error,
  disabled,
  onInValid,
  keyPattern,
  focusOnParent,
  onBlur,
  cursor,
  options,
  ...props
}) => (
  <Autocomplete
    cursor={cursor}
    disabled={disabled}
    error={error}
    focusOnParent={focusOnParent}
    icon={!value ? <Pictogram icon="search" /> : (
      <SVGButton onClick={() => onChange('')}>
        <Pictogram icon="close" />
      </SVGButton>
    )}
    keyPattern={keyPattern}
    label={label}
    name={name}
    onBlur={onBlur}
    onChange={onChange}
    onInValid={onInValid}
    options={options}
    pattern={pattern}
    required={required}
    value={value}
    {...props} // eslint-disable-line react/jsx-props-no-spreading
  />
);
