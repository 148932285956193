import styled, { css } from 'styled-components';
import { nnColors } from '../../colors';
import { MarginsWrapper } from '../Margins';
import { Pictogram } from '../Pictogram/Pictogram';
import { Typo } from '../Typo';

const ErrorFieldWrapper = styled.span<{ error: boolean }>`
  align-items: center;
  margin-top: 6px;
  ${Typo} {
    color: ${nnColors.red};
  }
  ${({ error }) => (error
      && css`
        display: flex;
      `)
    || css`
      display: none;
    `}
`;

const ErrorPictogram = styled(Pictogram)`
  min-width: 14px;
  min-height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
`;

export const ErrorField: React.FC<{ error?: string }> = ({
  error,
  ...props
}) => (
  <ErrorFieldWrapper
    {...props} // eslint-disable-line react/jsx-props-no-spreading
    error={!!error}
  >
    <ErrorPictogram icon="alert-small" />
    <MarginsWrapper marginLeft="XS">
      <Typo $type="caption">{error}</Typo>
    </MarginsWrapper>
  </ErrorFieldWrapper>
);
