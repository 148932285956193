import React from 'react';
import classNames from 'classnames';

import { Icon, IconType } from '../Icon';

import classes from './Infotip.module.scss';

interface InfotipProps {
    /** Determines infotip icon
     * @default "IconType.InfoRound"
     **/
    iconType?: IconType;
    /** Optional class names to appear on the container. */
    className?: string;
}

export const Infotip: React.FC<InfotipProps> = (props) => {
    const { children, className, iconType = IconType.InfoRound } = props;

    const infotipClass = classNames(
        classes.infotip,
        className,
    );

    return (
        <div className={infotipClass}>
            <Icon type={iconType} />
            <div className={classes.infotipContent}>
                {children}
            </div>
        </div>
    );
};
