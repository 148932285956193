import { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { primaryColors, secondarySuplement } from '../../colors/colors';
import { Typo } from '../Typo/Typo';
import { CaptionField } from './CaptionField';
import { ErrorField } from './ErrorField';

export interface FormElementProps {
  label?: string;
  required?: boolean;
  error?: string;
  caption?: string;
  disabled?: boolean;
  showInvalid?: boolean;
  filledUp?: boolean;
  cursor?: string;
  icon?: JSX.Element | ReactNode;
  className?: string;
  dense?: boolean;
}

interface InputValidationProps {
  disabled?: boolean;
  $required?: boolean;
  cursor?: string;
  dense?: boolean;
}

const InputWrapper = styled.span`
  position: relative;
  box-sizing: border-box;
  display: flex;
`;

interface StyledLabelProps extends InputValidationProps {
  filledUp: boolean;
  showInvalid?: boolean;
}

const StyledLabel = styled(Typo)<StyledLabelProps>`
  position: absolute;
  padding: 10px 44px 0 16px;
  font-family: NNDagnyText;
  transition: font-size 150ms ease-in, padding 150ms ease-in;
  cursor: ${({ cursor }) => (cursor || 'text')};
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${({ dense }) => dense && css`
    display: none;
  `}

  ${({ $required }) => $required
    && css`
      &:after {
        content: '';
        position: absolute;
        background: ${primaryColors.orangeMedium};
        top: 27px;
        height: 4px;
        width: 4px;
        right: 36px;
        border-radius: 2px;
        transition: top 150ms;
      }
    `}

  ${({ disabled }) => (disabled
      && css`
        color: ${primaryColors.greyLight};
        cursor: not-allowed;
      `)
    || ''}

  ${({ showInvalid }) => (showInvalid && css`
    color: ${secondarySuplement.red};
  `) || ''}

  ${({ filledUp }) => (filledUp
      && css`
        font-size: 16px;
        padding: 21px 44px 0 16px;
      `)
    || css`
      font-size: 12px;
      padding: 10px 44px 0 16px;
      &:after {
        top: 18px;
      }
    `}
`;

const Label = styled.label`
  width: 100%;
`;

interface IconWrapperProps {
  disabled?: boolean;
  dense?: boolean;
}

const IconWrapper = styled.div<IconWrapperProps>`
  position: absolute;
  top: 18px;
  right: 16px;
  cursor: pointer;
  ${({ dense }) => (dense
    ? css`top: 13px;`
    : css`top: 18px;`)}
  ${({ disabled }) => (disabled
      && css`
        cursor: not-allowed;
      `)
    || ''}
`;

const FieldWrapper = styled.div`
  position: absolute;
  top: 60px;
`;

export const ElementFormWrapper: React.FC<FormElementProps> = ({
  label,
  required,
  error,
  caption,
  filledUp,
  disabled,
  showInvalid,
  children,
  cursor,
  icon,
  className,
  dense,
}) => (
  <InputWrapper className={className}>
    <Label>
      <StyledLabel
        $color="greyMedium"
        $required={required}
        $type="body"
        cursor={cursor}
        dense={dense}
        disabled={disabled}
        filledUp={!!filledUp}
        showInvalid={showInvalid}
      >
        {label}
      </StyledLabel>
      {children}
      <IconWrapper dense={dense} disabled={disabled}>{icon}</IconWrapper>
    </Label>
    <FieldWrapper>
      {error ? <ErrorField error={error} /> : <CaptionField caption={caption} /> }
    </FieldWrapper>
  </InputWrapper>
);
