import React, { MouseEvent, useCallback } from 'react';
import classNames from 'classnames';

import classes from './Link.module.scss';
import { LinkVariant } from './Link.enum';

interface LinkProps {
    /** Inner HTML */
    children: React.ReactNode;
    /** Target url */
    href: string;
    /** The target attribute specifies where to open the linked document */
    target?: string;
    /** Optional class names to appear on the container. */
    className?: string;
    /** Determines link is possible to enter/click */
    isDisabled?: boolean;
    /** Determines link variant
     * @default "LinkVariant.Primary"
     **/
    variant?: LinkVariant;
    /** callback function that will be called on click */
    onClick?: (event: MouseEvent) => void;
}

export const Link = (props: LinkProps) => {
    const {
        children, className, href, target, isDisabled,
        variant = LinkVariant.Primary, onClick,
    } = props;
    const linkClass = classNames(
        classes.link,
        {
            [classes.primary]: variant === LinkVariant.Primary,
            [classes.tertiary]: variant === LinkVariant.Tertiary,
        },
        className,
    );

    const handleOnClick = useCallback((event: MouseEvent): void => {
        if (isDisabled) {
            event.preventDefault();

            return;
        }

        if (onClick) {
            onClick(event);
        }
    }, [ isDisabled, onClick ]);

    return (
        <a
            href={href}
            target={target}
            className={linkClass}
            onClick={handleOnClick}
        >
            <span className={classNames(classes.linkText)}>
                {children}
            </span>
        </a>
    );
};
