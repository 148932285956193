import { IconSize } from './Icon.types';

// TODO: "naming-convention" rule does not support objects when detecting "constant values".
// Reconsider using different naming approach, or relaxing the rule.
// eslint-disable-next-line @typescript-eslint/naming-convention
export const ICON_IMG_SIZE_VALUE: Record<IconSize, number> = {
    [IconSize.sm]: 12,
    [IconSize.md]: 24,
    [IconSize.lg]: 48,
    [IconSize.xl]: 120,

    [IconSize.size12]: 12,
    [IconSize.size16]: 16,
    [IconSize.size24]: 24,
    [IconSize.size32]: 32,
    [IconSize.size48]: 48,
    [IconSize.size64]: 64,
    [IconSize.size120]: 120,
};

// TODO: See note about ICON_IMG_SIZE_VALUE
// eslint-disable-next-line @typescript-eslint/naming-convention
export const CIRCULAR_ICON_IMG_SIZE_VALUE: Record<IconSize, number> = {
    [IconSize.sm]: 8,
    [IconSize.md]: 16,
    [IconSize.lg]: 32,
    [IconSize.xl]: 80,

    [IconSize.size12]: 8,
    [IconSize.size16]: 12,
    [IconSize.size24]: 16,
    [IconSize.size32]: 24,
    [IconSize.size48]: 32,
    [IconSize.size64]: 48,
    [IconSize.size120]: 80,
};
