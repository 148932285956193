import { useRef, useState } from 'react';
import styled from 'styled-components';
import { useClickOutsideRef } from '../../hooks';
import { Input, InputProps } from '../Input/Input';

import { OptionsList, IOption } from '../Select/OptionsList';

const AutocompleteWrapper = styled.div`
  position: relative;
`;

export interface AutocompleteProps extends InputProps {
  options?: IOption<string>[];
}

export const Autocomplete: React.FC<AutocompleteProps> = ({
  onChange,
  value,
  label,
  required,
  pattern,
  name,
  error,
  disabled,
  icon,
  onInValid,
  keyPattern,
  focusOnParent,
  onBlur,
  cursor,
  options,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState<boolean | undefined>(undefined);
  const AutocompleteWrapperRef = useRef<HTMLDivElement>(null);
  useClickOutsideRef(AutocompleteWrapperRef, () => setIsOpen(false));
  const optionsFilteredByValue = options && value
    ? options.filter((o) => (o.option ? o.option.includes(value) : o.value?.includes(value)))
    : options;
  return (
    <AutocompleteWrapper ref={AutocompleteWrapperRef}>
      <Input
        cursor={cursor}
        disabled={disabled}
        error={error}
        focusOnParent={focusOnParent}
        icon={icon}
        keyPattern={keyPattern}
        label={label}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={() => (options ? setIsOpen(true) : setIsOpen(false))}
        onInValid={onInValid}
        pattern={pattern}
        required={required}
        value={value}
        {...props} // eslint-disable-line react/jsx-props-no-spreading
      />
      {optionsFilteredByValue && (
        <OptionsList
          isOpen={isOpen}
          onChange={(onChangeValue?: string | null) => onChange(onChangeValue || '')}
          options={optionsFilteredByValue}
          setIsOpen={setIsOpen}
          value={value}
        />
      )}
    </AutocompleteWrapper>
  );
};
