import styled, { css } from 'styled-components';
import React, { useCallback } from 'react';
import { nnColors } from '../../colors';
import { Button } from '../Button';
import { Grid } from '../Grid';
import IconSliders from './sliders.svg';
import { MarginsWrapper } from '../Margins';
import { Search } from '../Search/Search';
import { Select } from '../Select';
import { Typo } from '../Typo';
import { IOption } from '../Select/OptionsList';
import { Pictogram } from '../Pictogram/Pictogram';

const SearchBarWrapper = styled(Grid)`
  align-items: flex-start;
`;

const SearchSearchBar = styled(Search)<{ single: boolean }>`
  min-width: 299px;
  ${({ single }) => (single ? '' : css`border-radius: 0 3px 3px 0;`)}
  `;

const SelectSearchBar = styled(Select)`
  border-right: 0;
  min-width: 201px;
  border-radius: 3px 0 0 3px;

  &:hover, &:focus {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: -1px;
      height: calc(100% - 22px);
      width: 1px;
      background: ${nnColors.orangeMedium};
      z-index: 1;
      transition: background-color 150ms ease-in-out;
    }
  }
` as typeof Select;

const FilterButtonTypo = styled(Typo)``;

const FilterButton = styled.button`
  box-sizing: border-box;
  height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  border-left: 0;
  border-right: 0;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: ${nnColors.snowWhite};
  background: transparent;
  cursor: pointer;
  ${Typo} {
    transition: color 150ms;
  }

  &:hover {
    ${FilterButtonTypo} {
      color: ${nnColors.orangeMedium};
    }
  }
`;

const ResultCounterWrapper = styled(Grid)`
  justify-content: flex-end;
`;

export interface SearchBarProps {
  search?: string;
  onChangeSearch: (value: string) => void;
  select?: string | null;
  onChangeSelect?: (_?: string | null) => void;
  onClickFilterButton?: () => void;
  onSubmitForm: (e?: React.SyntheticEvent) => void;
  filterButton?: string
  results?: number;
  options?: IOption<string>[]
}

export const SearchBar = ({
  search, onChangeSearch, select, onChangeSelect, options, filterButton = 'Dodaj filtr', onClickFilterButton, onSubmitForm, results,
}: SearchBarProps): JSX.Element => {
  const handleOnClickEnter = useCallback((e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onSubmitForm(e);
    }
  }, [onSubmitForm]);
  return (
    <SearchBarWrapper align onKeyDown={(e: React.KeyboardEvent) => handleOnClickEnter(e)}>
      <MarginsWrapper marginRight="M">
        <Grid>
          {(options && onChangeSelect) && (
          <SelectSearchBar
            dense
            label="Gdzie szukać"
            onChange={onChangeSelect}
            options={options}
            value={select}
          />
          )}
          <SearchSearchBar
            dense
            label="Wyszukiwarka"
            onChange={onChangeSearch}
            single={!(options && onChangeSelect)}
            value={search}
          />
        </Grid>
      </MarginsWrapper>
      <MarginsWrapper marginRight="M">
        <Button $type="primary">Szukaj</Button>
      </MarginsWrapper>
      {onClickFilterButton && (
      <FilterButton onClick={onClickFilterButton} type="button">
        <Grid align>
          <MarginsWrapper marginRight="S">
            <Pictogram Icon={IconSliders} />
          </MarginsWrapper>
          <FilterButtonTypo $type="bodyBold" noMargin>
            {filterButton}
          </FilterButtonTypo>
        </Grid>
          {results !== undefined && (
          <ResultCounterWrapper>
            <Typo $type="body" noMargin>
              Liczba wyników:
            </Typo>
            <MarginsWrapper marginLeft="S">
              <Typo $type="bodyBold" noMargin>{results}</Typo>
            </MarginsWrapper>
          </ResultCounterWrapper>
          )}
      </FilterButton>
      )}
    </SearchBarWrapper>
  );
};
