/* eslint-disable import/no-extraneous-dependencies */
import styled from 'styled-components';

export const SVGButton = styled.button`
  border: none;
  background: none;
  box-shadow: none;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  cursor: pointer;
`;
