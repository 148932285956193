import { IconType } from './Icon.types';
import { PictographType } from './Pictograph.types';
import { FlagType } from './Flag.types';

// Enums merge is currently not natively supported by TS, the best solution is
// to create an entity on type-level and value-level
// https://github.com/Microsoft/TypeScript/issues/17592#issuecomment-449440944
export type IconTypeAll = IconType | PictographType | FlagType;

// Rules explicitly disabled to allow the aforementioned enum merging
// eslint-disable-next-line @typescript-eslint/no-redeclare, @typescript-eslint/naming-convention
export const IconTypeAll = {
    ...IconType,
    ...PictographType,
    ...FlagType,
};
