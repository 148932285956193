import Account from '~/assets/pictographics/Account.svg';
import Bike from '~/assets/pictographics/Bike.svg';
import Boat from '~/assets/pictographics/Boat.svg';
import Buffer from '~/assets/pictographics/Buffer.svg';
import Car from '~/assets/pictographics/Car.svg';
import Children from '~/assets/pictographics/Children.svg';
import Clothing from '~/assets/pictographics/Clothing.svg';
import Cloudy from '~/assets/pictographics/Cloudy.svg';
import Co2 from '~/assets/pictographics/Co2.svg';
import DamageInsurance from '~/assets/pictographics/Damage_Insurance.svg';
import DamageInsuranceBusiness from '~/assets/pictographics/Damage_Insurance_Business.svg';
import Dentist from '~/assets/pictographics/Dentist.svg';
import FamilyExpansion from '~/assets/pictographics/Family_Expansion.svg';
import Funeral from '~/assets/pictographics/Funeral.svg';
import Furniture1 from '~/assets/pictographics/Furniture_1.svg';
import Furniture2 from '~/assets/pictographics/Furniture_2.svg';
import Furniture3 from '~/assets/pictographics/Furniture_3.svg';
import Furniture4 from '~/assets/pictographics/Furniture_4.svg';
import Fysiotherapie from '~/assets/pictographics/Fysiotherapie.svg';
import Glasses from '~/assets/pictographics/Glasses.svg';
import Health from '~/assets/pictographics/Health.svg';
import HealthInsurance from '~/assets/pictographics/Health_Insurance.svg';
import HealthInsuranceCollective from '~/assets/pictographics/Health_Insurance_Collective.svg';
import HealthList from '~/assets/pictographics/Health_List.svg';
import Hobby1 from '~/assets/pictographics/Hobby_1.svg';
import Hobby2 from '~/assets/pictographics/Hobby_2.svg';
import Home from '~/assets/pictographics/Home.svg';
import IncomeInsuranceCollective from '~/assets/pictographics/Income_Insurance_Collective.svg';
import InformalCare from '~/assets/pictographics/Informalcare.svg';
import Invest1 from '~/assets/pictographics/Invest_1.svg';
import Kids from '~/assets/pictographics/Kids.svg';
import Lightning from '~/assets/pictographics/Lightning.svg';
import Loan from '~/assets/pictographics/Loan.svg';
import Mortgage from '~/assets/pictographics/Mortgage.svg';
import NaturalHealingMethods from '~/assets/pictographics/Naturalhealingmethods.svg';
import Orthodontics from '~/assets/pictographics/Orthodontics.svg';
import Overview from '~/assets/pictographics/Overview.svg';
import Partner from '~/assets/pictographics/Partner.svg';
import Party1 from '~/assets/pictographics/Party_1.svg';
import Party2 from '~/assets/pictographics/Party_2.svg';
import Pension from '~/assets/pictographics/Pension.svg';
import Pension1 from '~/assets/pictographics/Pension_1.svg';
import Pension2 from '~/assets/pictographics/Pension_2.svg';
import Products from '~/assets/pictographics/Products.svg';
import Refinancing from '~/assets/pictographics/Refinancing.svg';
import Repair1 from '~/assets/pictographics/Repair_1.svg';
import Repair2 from '~/assets/pictographics/Repair_2.svg';
import Restaurant from '~/assets/pictographics/Restaurant.svg';
import Risico from '~/assets/pictographics/Risico.svg';
import Saving from '~/assets/pictographics/Saving.svg';
import Service from '~/assets/pictographics/Service.svg';
import Settings from '~/assets/pictographics/Settings.svg';
import Sickness from '~/assets/pictographics/Sickness.svg';
import SicknessAbsence from '~/assets/pictographics/Sickness_Absence.svg';
import SicknessAbsenceFrequency from '~/assets/pictographics/Sickness_Absence_Frequency.svg';
import Sock from '~/assets/pictographics/Sock.svg';
import Sport1 from '~/assets/pictographics/Sport_1.svg';
import Sport2 from '~/assets/pictographics/Sport_2.svg';
import Sport3 from '~/assets/pictographics/Sport_3.svg';
import SportsAllowance from '~/assets/pictographics/Sportsallowance.svg';
import Study2 from '~/assets/pictographics/Study2.svg';
import Study1 from '~/assets/pictographics/Study_1.svg';
import Sun from '~/assets/pictographics/Sun.svg';
import Target from '~/assets/pictographics/Target.svg';
import Trailer from '~/assets/pictographics/Trailer.svg';
import Trash from '~/assets/pictographics/Trash.svg';
import Vacation from '~/assets/pictographics/Vacation.svg';
import Vacation3 from '~/assets/pictographics/Vacation3.svg';
import Vacation2 from '~/assets/pictographics/Vacation_2.svg';
import Vacation4 from '~/assets/pictographics/Vacation_4.svg';
import Water from '~/assets/pictographics/Water.svg';
import WorkLonger from '~/assets/pictographics/Work_Longer.svg';
import Files from '~/assets/pictographics/files.svg';
import Human from '~/assets/pictographics/human.svg';
import Medicare from '~/assets/pictographics/medicare.svg';
import Money from '~/assets/pictographics/money.svg';
import Objects from '~/assets/pictographics/objects.svg';
import Travel from '~/assets/pictographics/travel.svg';
import Umbrella from '~/assets/pictographics/umbrella.svg';
import Weather from '~/assets/pictographics/weather.svg';
import IdDocumentFront from '~/assets/pictographics/id-document-front.svg';
import IdDocumentRear from '~/assets/pictographics/id-document-rear.svg';
import DocumentDetailed from '~/assets/pictographics/document-detailed.svg';
import RoomPicDetailed from '~/assets/pictographics/room-pic-detailed.svg';
import RoomPicCorners from '~/assets/pictographics/room-pic-corners.svg';
import HotLead from '~/assets/pictographics/hot-lead.svg';
import ColdLead from '~/assets/pictographics/cold-lead.svg';
import ColdLead2 from '~/assets/pictographics/cold-lead-2.svg';
import ColdLead3 from '~/assets/pictographics/cold-lead-3.svg';
import SafeWheel from '~/assets/pictographics/safe-wheel.svg';
import CancerRibbon from '~/assets/pictographics/cancer-ribbon.svg';
import Cookies from '~/assets/pictographics/cookies.svg';
import Grave from '~/assets/pictographics/grave.svg';
import Needle from '~/assets/pictographics/needle.svg';
import Thermometer from '~/assets/pictographics/thermometer.svg';
import Genetics from '~/assets/pictographics/genetics.svg';
import WheelChair from '~/assets/pictographics/wheelchair.svg';
import Recovery from '~/assets/pictographics/recovery.svg';
import Crutch from '~/assets/pictographics/crutch.svg';
import Xray from '~/assets/pictographics/xray.svg';
import Pill from '~/assets/pictographics/pill.svg';
import Familly from '~/assets/pictographics/familly.svg';
import EmployeeUmbrella from '~/assets/pictographics/employee-umbrella.svg';


import { PictographType } from './Pictograph.types';

export const pictographSet = {
    [PictographType.Account]: Account,
    [PictographType.Bike]: Bike,
    [PictographType.Boat]: Boat,
    [PictographType.Buffer]: Buffer,
    [PictographType.Car]: Car,
    [PictographType.Children]: Children,
    [PictographType.Clothing]: Clothing,
    [PictographType.Cloudy]: Cloudy,
    [PictographType.Co2]: Co2,
    [PictographType.DamageInsurance]: DamageInsurance,
    [PictographType.DamageInsuranceBusiness]: DamageInsuranceBusiness,
    [PictographType.Dentist]: Dentist,
    [PictographType.FamilyExpansion]: FamilyExpansion,
    [PictographType.Funeral]: Funeral,
    [PictographType.Furniture1]: Furniture1,
    [PictographType.Furniture2]: Furniture2,
    [PictographType.Furniture3]: Furniture3,
    [PictographType.Furniture4]: Furniture4,
    [PictographType.Fysiotherapie]: Fysiotherapie,
    [PictographType.Glasses]: Glasses,
    [PictographType.Health]: Health,
    [PictographType.HealthInsurance]: HealthInsurance,
    [PictographType.HealthInsuranceCollective]: HealthInsuranceCollective,
    [PictographType.HealthList]: HealthList,
    [PictographType.Hobby1]: Hobby1,
    [PictographType.Hobby2]: Hobby2,
    [PictographType.Home]: Home,
    [PictographType.IncomeInsuranceCollective]: IncomeInsuranceCollective,
    [PictographType.InformalCare]: InformalCare,
    [PictographType.Invest1]: Invest1,
    [PictographType.Kids]: Kids,
    [PictographType.Lightning]: Lightning,
    [PictographType.Loan]: Loan,
    [PictographType.Mortgage]: Mortgage,
    [PictographType.NaturalHealingMethods]: NaturalHealingMethods,
    [PictographType.Orthodontics]: Orthodontics,
    [PictographType.Overview]: Overview,
    [PictographType.Partner]: Partner,
    [PictographType.Party1]: Party1,
    [PictographType.Party2]: Party2,
    [PictographType.Pension]: Pension,
    [PictographType.Pension1]: Pension1,
    [PictographType.Pension2]: Pension2,
    [PictographType.Products]: Products,
    [PictographType.Refinancing]: Refinancing,
    [PictographType.Repair1]: Repair1,
    [PictographType.Repair2]: Repair2,
    [PictographType.Restaurant]: Restaurant,
    [PictographType.Risico]: Risico,
    [PictographType.Saving]: Saving,
    [PictographType.Service]: Service,
    [PictographType.Settings]: Settings,
    [PictographType.Sickness]: Sickness,
    [PictographType.SicknessAbsence]: SicknessAbsence,
    [PictographType.SicknessAbsenceFrequency]: SicknessAbsenceFrequency,
    [PictographType.Sock]: Sock,
    [PictographType.Sport1]: Sport1,
    [PictographType.Sport2]: Sport2,
    [PictographType.Sport3]: Sport3,
    [PictographType.SportsAllowance]: SportsAllowance,
    [PictographType.Study2]: Study2,
    [PictographType.Study1]: Study1,
    [PictographType.Sun]: Sun,
    [PictographType.Target]: Target,
    [PictographType.Trailer]: Trailer,
    [PictographType.Trash]: Trash,
    [PictographType.Vacation]: Vacation,
    [PictographType.Vacation3]: Vacation3,
    [PictographType.Vacation2]: Vacation2,
    [PictographType.Vacation4]: Vacation4,
    [PictographType.Water]: Water,
    [PictographType.WorkLonger]: WorkLonger,
    [PictographType.Files]: Files,
    [PictographType.Human]: Human,
    [PictographType.Medicare]: Medicare,
    [PictographType.Money]: Money,
    [PictographType.Objects]: Objects,
    [PictographType.Travel]: Travel,
    [PictographType.Umbrella]: Umbrella,
    [PictographType.Weather]: Weather,
    [PictographType.IdDocumentFront]: IdDocumentFront,
    [PictographType.IdDocumentRear]: IdDocumentRear,
    [PictographType.DocumentDetailed]: DocumentDetailed,
    [PictographType.RoomPicDetailed]: RoomPicDetailed,
    [PictographType.RoomPicCorners]: RoomPicCorners,
    [PictographType.HotLead]: HotLead,
    [PictographType.ColdLead]: ColdLead,
    [PictographType.ColdLead2]: ColdLead2,
    [PictographType.ColdLead3]: ColdLead3,
    [PictographType.SafeWheel]: SafeWheel,
    [PictographType.CancerRibbon]: CancerRibbon,
    [PictographType.Cookies]: Cookies,
    [PictographType.Grave]: Grave,
    [PictographType.Needle]: Needle,
    [PictographType.Thermometer]: Thermometer,
    [PictographType.Genetics]: Genetics,
    [PictographType.WheelChair]: WheelChair,
    [PictographType.Recovery]: Recovery,
    [PictographType.Crutch]: Crutch,
    [PictographType.Xray]: Xray,
    [PictographType.Pill]: Pill,
    [PictographType.Familly]: Familly,
    [PictographType.EmployeeUmbrella]: EmployeeUmbrella,
};
