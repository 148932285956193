import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { Sizes, ButtonBase } from './ButtonBase';

function getCSSforSize($size?: Sizes): FlattenSimpleInterpolation {
  if ($size === 's') {
    return css`
      height: 24px;
      padding: 2px 24px;
    `;
  } if ($size === 'm') {
    return css`
      height: 32px;
      padding: 5px 24px;
    `;
  }

  return css`
    height: 48px;
    padding: 13px 24px;
  `;
}

export const Button = styled(ButtonBase)`
  ${({ $size }) => getCSSforSize($size)};
`;
