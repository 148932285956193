/* eslint-disable react/no-multi-comp */
/* eslint-disable react/jsx-props-no-spreading */
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { nnColors } from '../../colors';
import { MarginsWrapper } from '../Margins';
import { Pictogram, Props as PictogramProps } from '../Pictogram/Pictogram';
import { Typo } from '../Typo';

const iconLinkStyles = ($disabled: boolean) => css`
  height: 24px;
  display: flex;
  align-items: center;
  cursor: pointer;

  ${$disabled
    ? css`
      ${Typo} {
        color: ${nnColors.greyLight}
      }
    `
    : css`
    &:hover {
      ${Typo} {
        color: ${nnColors.orangeMedium}
      }
    }
  `}
`;

const StyledIconLink = styled(Link)<{ $disabled: boolean }>`
  ${({ $disabled }) => iconLinkStyles($disabled)}
  text-decoration: none;
`;

const StyledButtonIconLink = styled.button<{ $disabled: boolean }>`
  ${({ $disabled }) => iconLinkStyles($disabled)}
  border: none;
  background: transparent;
`;

export interface IconLinkProps extends PictogramProps{
  text: string;
  to: string;
  disabled?: boolean;
}

export const IconLink = ({
  text, icon, disabled, to,
}: IconLinkProps): JSX.Element => (
  <StyledIconLink $disabled={!!disabled} to={to}>
    <Pictogram icon={icon || 'arrow-right'} iconColor={disabled ? 'greyLight' : 'orangeMedium'} />
    <MarginsWrapper marginLeft="S">
      <Typo $type="bodyBold" noMargin>
        {text}
      </Typo>
    </MarginsWrapper>
  </StyledIconLink>
);

export interface ButtonIconLinkProps extends PictogramProps {
  text: string;
  disabled?: boolean;
  onClick?: () => void;
}

export const ButtonIconLink = ({
  text, onClick, icon, disabled, ...props
}: ButtonIconLinkProps): JSX.Element => (
  <StyledButtonIconLink $disabled={!!disabled} onClick={onClick} type="button" {...props}>
    <Pictogram icon={icon || 'arrow-right'} iconColor={disabled ? 'greyLight' : 'orangeMedium'} />
    <MarginsWrapper marginLeft="S">
      <Typo $type="bodyBold" noMargin>
        {text}
      </Typo>
    </MarginsWrapper>
  </StyledButtonIconLink>
);
