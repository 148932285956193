import React from 'react';

import { Icon, IconType } from '../Icon';

import classes from './Toast.module.scss';
interface ToastContentProps {
    message: string;
    iconType: IconType;
}

export const ToastContent: React.FC<ToastContentProps> = (props) => {
    const { message, iconType } = props;

    return (
        <div className={classes.toastContent}>
            <div>
                <Icon type={iconType} />
            </div>
            <div>
                {message}
            </div>
        </div>
    );
};
