import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import classNames from 'classnames';
import { ToastContainer as RtToastContainer, ToastContainerProps } from 'react-toastify';

import { minimumDuration, toastBasePosition } from '~/components/Toast/Toast.constants';

import classes from './Toast.module.scss';

export const ToastContainer: React.FC<ToastContainerProps> = (props) => {
    const { className, ...restToastProps } = props;

    const toastContainerClass = classNames(
        classes.toastContainer,
        className,
    );

    return (
        <RtToastContainer
            className={toastContainerClass}
            position={toastBasePosition}
            autoClose={minimumDuration}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnHover
            draggable
            pauseOnFocusLoss
            enableMultiContainer
            {...restToastProps}
        />
    );
};
