import styled, { CSSProp, css } from 'styled-components';
import React from 'react';
import { MarginsProps, MarginsWrapper } from '../Margins/Margins';
import { nnColors } from '../../colors';
import { Grid } from '../Grid/Grid';
import { icons } from './icons';

interface PictogramFrameProps {
  iconColor?: keyof typeof nnColors;
  fill?: keyof typeof nnColors;
  iconSize?: 'xxl' | 'xl' | 'l' | 'm' | 's' | 'xs';
}

function getSizeValue(iconSize?: PictogramFrameProps['iconSize']): number {
  if (iconSize === 'xxl') {
    return 80;
  }

  if (iconSize === 'xl') {
    return 48;
  }

  if (iconSize === 'l') {
    return 32;
  }

  if (iconSize === 's') {
    return 10.67;
  }

  if (iconSize === 'xs') {
    return 8;
  }

  return 24;
}

const PictogramFrame = styled(MarginsWrapper.withComponent(Grid))<PictogramFrameProps>`
  overflow: hidden;
  position: relative;

  min-width: ${({ iconSize }) => getSizeValue(iconSize)}px;
  min-height: ${({ iconSize }) => getSizeValue(iconSize)}px;
  max-width: ${({ iconSize }) => getSizeValue(iconSize)}px;
  max-height: ${({ iconSize }) => getSizeValue(iconSize)}px;

  svg {
    position: absolute;
    left: 0;
    right: 0;
    transform: scale(${({ iconSize }) => getSizeValue(iconSize) / 24});
    transform-origin: 0 0 ;
  }

  ${({ iconColor }: PictogramFrameProps): CSSProp => (iconColor && css`
    svg path {
      stroke: ${nnColors[iconColor]};
    }
  `) || ''};

  ${({ fill }: PictogramFrameProps): CSSProp => (fill && css`
    svg path{
      fill: ${nnColors[fill]};
    }
  `) || ''};
`;

export interface Props extends PictogramFrameProps, MarginsProps {
  icon?: keyof typeof icons;
  Icon?: typeof React.Component;
}

export const Pictogram = ({
  icon,
  Icon,
  ...props
}: Props): JSX.Element => {
  const IconFromIndex = icon && icons[icon];

  return (
    <PictogramFrame
      {...props} // eslint-disable-line
    >
      {Icon && <Icon />}
      {!Icon && IconFromIndex && <IconFromIndex />}
    </PictogramFrame>
  );
};
