/* eslint-disable import/no-extraneous-dependencies */
import styled from 'styled-components';
import DayPicker from 'react-day-picker';
import { primaryColors } from '../../../colors/colors';
import { DayPickerDefaultStyles } from './DayPickerDefaultStyles';

export const DayPickerStyled = styled(DayPicker)`
  ${DayPickerDefaultStyles}
  box-shadow: 0px 0px 4px rgba(65, 65, 65, 0.1),
    0px 8px 16px rgba(65, 65, 65, 0.1), 0px 16px 32px rgba(65, 65, 65, 0.2);
  position: absolute;
  border-radius: 3px;
  margin-top: -22px;
  background: white;
  z-index: 1;

  .row {
    margin: 0 !important;
  }

  .DayPicker-wrapper {
    outline: none;
    padding-bottom: 9px;
  }

  .DayPicker-Weekdays {
    border-bottom: 1px solid ${primaryColors.greyWhite};
  }

  .DayPicker-Weekday {
    font-family: NNDagnyText;
    font-size: 1rem;
  }

  .DayPicker-Weekday,
  .DayPicker-Day {
    color: ${primaryColors.greyDark};
  }

  .DayPicker-Month {
    width: 327px;
    margin-top: 20px;
  }

  .DayPicker-Caption {
    margin-bottom: 20px;
  }

  .DayPicker-Day {
    position: relative;
    border-radius: 3px;
    z-index: 2;
    outline: none;
    font-family: 'NNDagnyText';

    &::before {
      content: ' ';
      pointer-events: none;
      position: absolute;
      border-radius: 3px;
      left: 2px;
      right: 2px;
      top: 2px;
      bottom: 2px;
      z-index: -1;
    }
  }

  .DayPicker-Day--today:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside) {
    font-family: NNDagnyDisplay;
  }

  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    color: ${primaryColors.greyDark};
    background-color: unset !important;

    &::before {
      border: 1px solid ${primaryColors.orangeMedium};
      box-shadow: 0px 5px 20px rgba(240, 128, 0, 0.148805);
      background-color: transparent !important;
    }
  }

  .DayPicker-Day--selected {
    background-color: transparent !important;

    &::before {
      background-color: ${primaryColors.orangeMedium} !important;
    }
  }

  .DayPicker-Day--selected.DayPicker-Day--outside::before {
    background-color: transparent !important;
  }

  .DayPicker-Day--today.DayPicker-Day--outside::before {
    border: 1px solid transparent;
  }
`;
