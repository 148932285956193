import { useEffect, RefObject } from 'react';

export function useClickOutsideRef(ref: RefObject<HTMLElement>, onClickOutside: () => void): void {
  useEffect((): () => void => {
    function onHandleWindowClick(event: MouseEvent): void {
      const { current } = ref;
      if (!current?.contains(event.target as Node)) {
        onClickOutside();
      }
    }
    window.addEventListener('click', onHandleWindowClick);

    return (): void => {
      window.removeEventListener('click', onHandleWindowClick);
    };
  }, [onClickOutside, ref]);
}
