import React from 'react';

import { Icon } from '~/components/Icon/Icon.component';
import { IconType, IconSize, IconMode } from '~/components/Icon/Icon.types';

import classes from './AnimatedLoader.module.scss';

interface AnimatedLoaderProps {
    /** icon size enum that provides sizes as: sm | md | lg | xl */
    size?: IconSize;
}

export const AnimatedLoader: React.FC<AnimatedLoaderProps> = (props) => {
    const { size } = props;

    return (
        <Icon
            className={classes.loaderIcon}
            type={IconType.Loader}
            mode={IconMode.default}
            size={size}
        />
    );
};
