/* eslint-disable import/no-extraneous-dependencies */
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { nnColors, primaryColors } from '../../colors';

export const SideBarTextLink = styled.p`
  width: 0;
  margin-left: 8px;
  overflow: hidden;
  font-family: NNDagnyText;
  font-size: 16px;
  line-height: 20px;
  color: ${primaryColors.greyDark};
  opacity: 0;
  transition: width 200ms, opacity 150ms, color 150ms ease-in;
`;

const SidebarButton = ($iconColorType: string, $active: boolean) => css`
  width: auto;
  height: 32px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
  text-decoration: none;

  path {
    transition: stroke 150ms ease-in, fill 150ms ease-in;
  }

  &:hover {
    path {
      ${() => ($iconColorType
    && $iconColorType === 'stroke'
    && css`
            stroke: ${primaryColors.orangeMedium};
          `)
    || css`
          fill: ${primaryColors.orangeMedium};
        `}
    }
    ${SideBarTextLink} {
      font-family: NNDagnyDisplay;
      color: ${primaryColors.orangeMedium};
    }
  }

    ${() => ($active
    && css`
        path {
          ${($iconColorType
        && $iconColorType === 'stroke'
        && css`
              stroke: ${primaryColors.orangeMedium};
            `)
      || css`
            fill: ${primaryColors.orangeMedium};
          `}
        }
        ${SideBarTextLink} {
          font-family: NNDagnyDisplay;
          color: ${primaryColors.orangeMedium};
        }
      `)
    || ''}

  &:after {
    content: '';
    position: absolute;
    height: 32px;
    width: 4px;
    background: transparent;
    border-radius: 0;
    transition: background-color 200ms, border-radius 200ms;
  }
`;

export const SideBarStyledLink = styled(Link) <{ $active?: boolean; $iconColorType: string }>`
  white-space: nowrap;
  ${({ $iconColorType, $active }) => SidebarButton($iconColorType, !!$active)}
  &:after {
    content: '';
    position: absolute;
    height: 32px;
    width: 4px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    right: 0;
    transition: background-color 200ms ease-in-out;
  }
  ${({ $active }) => ($active ? css`
    &:after {
      background: ${primaryColors.orangeMedium};
    }
  `
    : css`
      &:after {
        background: transparent;
    }
  `)}
`;

const sideBarDropdownLines = (isOpen: boolean) => css`
  content: "";
  position: absolute;
  width: calc(100% + 16px);
  height: 1px;
  left: -16px;
  margin-top: -8px;
  transition: background-color 150ms ease-in-out;
  background-color: ${isOpen ? nnColors.greyWhite : 'transparent'};
`;

interface DropDownProps {
  animationTiming: string;
  isOpen: boolean;
}

export const SidebarStyledDropdownWrapper = styled.div <DropDownProps>`
  position: relative;
  transition: ${({ animationTiming }) => `max-height ${animationTiming}ms ease-in-out`};
  margin-bottom: 16px;
  ${({ isOpen }) => css`
      max-height: ${isOpen ? '100%' : '32px'};
      &:before {
        ${sideBarDropdownLines(isOpen)}
      }
      &:after {
        ${sideBarDropdownLines(isOpen)}
      }
  `}
`;

export const SidebarStyledList = styled.div<DropDownProps>`
  overflow: hidden;
  transition: ${({ animationTiming }) => `max-height ${animationTiming}ms ease-in-out, opacity 150ms ease-in-out`};
  ${({ isOpen }) => (isOpen ? css`
      max-height: 100%;
      opacity: 1;
  ` : css`
      max-height: 0;
      opacity: 0;
      ${SideBarStyledLink} {
        &::after {
          display: none;
        }
      }
  `)}
`;

interface SidebarStyledDropdownProps {
  isOpen: boolean;
  $iconColorType: string
  $active?: boolean;
}

export const SidebarStyledDropdown = styled.button<SidebarStyledDropdownProps>`
  appearance: none;
  border: none;
  padding: 0;
  background: transparent;
  text-align: initial;
  ${({ $iconColorType, $active }) => SidebarButton($iconColorType, !!$active)}
  &:before {
    content: "";
    position: absolute;
    left: -8px;
    border-left: 0px solid grey;
    border-right: 6px solid grey;
    border-top: 6px solid white;
    border-bottom: 0px solid grey;
    transition: transform 150ms ease-in-out;
    transform: ${({ isOpen }) => (isOpen ? 'rotate(45deg)' : 'rotate(-45deg)')};
  }
`;

export const SideBarWrapper = styled.aside`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100vh;
  background: white;
  box-shadow: 0px 0px 1px rgba(65, 65, 65, 0.1),
    0px 1px 2px rgba(65, 65, 65, 0.1), 0px 2px 6px rgba(65, 65, 65, 0.1);
  width: 60px;
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  transition: width 275ms;
  padding-left: 16px;
  box-sizing: border-box;

  &:hover {
    width: 204px;
    ${SideBarTextLink} {
      opacity: 1;
      width: calc(100% - 40px);
    }
  }
`;

// const DropdownWrapper;
