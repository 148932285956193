import {
  useCallback,
  useEffect, useRef, useState,
} from 'react';
import styled, { css } from 'styled-components';
import { primaryColors, secondarySuplement } from '../../colors/colors';
import { useClickOutsideRef } from '../../hooks/useClickOutside';
import { ElementFormWrapper } from '../Form/ElementFormWrapper';
import { IOption, OptionsList } from './OptionsList';
import { Pictogram } from '../Pictogram/Pictogram';

interface SelectButtonProps {
  showInvalid?: boolean;
  disabled?: boolean;
  dense?: boolean;
}

export const SelectButton = styled.button<SelectButtonProps>`
  border: 1px solid ${primaryColors.greyLight};
  color: ${primaryColors.greyDark};
  background: white;
  text-align: left;
  border-radius: 3px;
  box-sizing: border-box;
  outline: none;
  font-family: NNDagnyText;
  font-size: 16px;
  margin-bottom: 22px;
  width: 100%;
  transition: border 150ms ease-in;
  cursor: pointer;

  ${({ dense }) => (dense
    ? css`
      height: 48px;
      padding: 14px 44px 10px 16px;
  `
    : css`
      height: 60px;
      padding: 30px 44px 10px 16px;
  `)}

  ${({ disabled }) => (disabled
      && css`
        pointer-events: none;
        border-color: ${primaryColors.greyLight};
        color: ${primaryColors.greyLight};
        background: ${primaryColors.snowWhite};
        cursor: not-allowed;
      `)
    || css`
      &:hover,
      &:focus {
        border-color: ${primaryColors.orangeMedium};
        box-shadow: 0px 5px 20px rgba(240, 128, 0, 0.148805);
      }
    `}

  ${({ showInvalid }) => (showInvalid
      && css`
        border-color: ${secondarySuplement.red};
        &:focus {
          border-color: ${secondarySuplement.red};
          box-shadow: none;
        }
      `)
    || css``}
`;

interface SelectArrowWrapperProps {
  isOpen: boolean;
  dense?: boolean;
}

const SelectArrowWrapper = styled.div<SelectArrowWrapperProps>`
  position: absolute;
  right: 12px;
  top: 18px;
  transition: transform 150ms ease-in, top 150ms ease-in;

  ${({ dense }) => (dense
    ? css`top: 13px;`
    : css`top: 18px;`)}

  ${({ isOpen, dense }) => ((isOpen && dense)
      && css`
        transform: rotate(180deg);
      `)
  || ''}

  ${({ isOpen, dense }) => ((isOpen && !dense)
      && css`
        transform: rotate(180deg);
      `)
    || ''}
`;

export interface SelectProps<T> {
  options: IOption<T>[];
  value?: T;
  error?: string;
  disabled?: boolean;
  required?: boolean;
  onInValid?: (_: boolean) => void;
  label?: string;
  dense?: boolean;
  onChange: (value: T) => void;
}

export function Select <T>({ // eslint-disable-line react/function-component-definition
  options,
  value,
  error,
  required,
  label,
  onChange,
  disabled,
  onInValid,
  dense,
  ...props
}:SelectProps<T>): JSX.Element {
  const [isOpen, setIsOpen] = useState<boolean | undefined>(undefined);
  const [blurred, setIsBlurred] = useState<boolean>(false);
  const [focused, setIsFocused] = useState<boolean>(false);
  const spanRef = useRef<HTMLSpanElement>(null);

  const handleOnBlur = useCallback(() => {
    setIsFocused(false);
    setIsBlurred(true);
    setIsOpen(false);
  }, []);

  useClickOutsideRef(spanRef, handleOnBlur);

  const nameValue = options.filter((option) => option.value === value)[0]
    ?.option;

  // invalid
  let isInvalid = false;
  let isInvalidAndBlurred = false;

  if (required && !value) { // when required blurred and not filled
    isInvalid = true;
  } else if (error) { // when error from parent is given
    isInvalid = true;
  }

  if (blurred) {
    isInvalidAndBlurred = isInvalid;
  }

  useEffect(
    () => onInValid && onInValid(isInvalid),
    [isInvalid, onInValid],
  );

  return (
    <span ref={spanRef}>
      <ElementFormWrapper
        cursor="pointer"
        dense={dense}
        disabled={disabled}
        error={error}
        filledUp={!(focused || !!value)}
        label={label}
        required={required}
        showInvalid={isInvalidAndBlurred}
      >
        <SelectButton
          dense={dense}
          disabled={disabled}
          onClick={(): void => (!disabled ? setIsOpen(!isOpen) : undefined)}
          showInvalid={isInvalidAndBlurred}
          type="button"
          {...props} // eslint-disable-line react/jsx-props-no-spreading
        >
          {value ? nameValue || value : undefined}
          <SelectArrowWrapper dense={dense} isOpen={!!isOpen}>
            <Pictogram icon="arrow-down" />
          </SelectArrowWrapper>
        </SelectButton>
        <OptionsList
          dense={dense}
          isOpen={isOpen}
          onChange={onChange}
          options={options}
          setIsOpen={setIsOpen}
          value={value}
        />
      </ElementFormWrapper>
    </span>
  );
}
