export enum IconType {
    AddRound = 'IconType.AddRound',
    Alert = 'IconType.Alert',
    ArrowDownBold = 'IconType.ArrowDownBold',
    ArrowDownFull = 'IconType.ArrowDownFull',
    ArrowDown = 'IconType.ArrowDown',
    ArrowLeftBold = 'IconType.ArrowLeftBold',
    ArrowLeft = 'IconType.ArrowLeft',
    ArrowRightBold = 'IconType.ArrowRightBold',
    ArrowRight = 'IconType.ArrowRight',
    ArrowUpBold = 'IconType.ArrowUpBold',
    ArrowUpFull = 'IconType.ArrowUpFull',
    ArrowUp = 'IconType.ArrowUp',
    Bell = 'IconType.Bell',
    Calendar = 'IconType.Calendar',
    Camera = 'IconType.Camera',
    Chart = 'IconType.Chart',
    Chat = 'IconType.Chat',
    Clock = 'IconType.Clock',
    Close = 'IconType.Close',
    Communication = 'IconType.Communication',
    Copy = 'IconType.Copy',
    Document = 'IconType.Document',
    Download = 'IconType.Download',
    Desktop = 'IconType.Desktop',
    Edit = 'IconType.Edit',
    Exit = 'IconType.Exit',
    EyeOff = 'IconType.EyeOff',
    Eye = 'IconType.Eye',
    Fingerprint = 'IconType.Fingerprint',
    Folder = 'IconType.Folder',
    Gear = 'IconType.Gear',
    Graph = 'IconType.Graph',
    Heart = 'IconType.Heart',
    Home = 'IconType.Home',
    Info = 'IconType.Info',
    InfoRound = 'IconType.InfoRound',
    Link = 'IconType.Link',
    Loader = 'IconType.Loader',
    Lock = 'IconType.Lock',
    Mail = 'IconType.Mail',
    Maximize = 'IconType.Maximize',
    MicrophoneOff = 'IconType.MicrophoneOff',
    Microphone = 'IconType.Microphone',
    Minimize = 'IconType.Minimize',
    MinusShort = 'IconType.MinusShort',
    Minus = 'IconType.Minus',
    Menu = 'IconType.Menu',
    Mobile = 'IconType.Mobile',
    MoreVertical = 'IconType.MoreVertical',
    More = 'IconType.More',
    Ok = 'IconType.Ok',
    Phone = 'IconType.Phone',
    PhoneOff = 'IconType.PhoneOff',
    Pin = 'IconType.Pin',
    Play = 'IconType.Play',
    Plus = 'IconType.Plus',
    Printer = 'IconType.Printer',
    Reload = 'IconType.Reload',
    Search = 'IconType.Search',
    Send = 'IconType.Send',
    Share = 'IconType.Share',
    Shield = 'IconType.Shield',
    Signature = 'IconType.Signature',
    ShoppingCart = 'IconType.ShoppingCart',
    SidesBold = 'IconType.SidesBold',
    Sides = 'IconType.Sides',
    Sliders = 'IconType.Sliders',
    SubrtactRound = 'IconType.SubtractRound',
    Star = 'IconType.Star',
    Trash = 'IconType.Trash',
    ToastAlertIcon = 'IconType.ToastAlertIcon',
    ToastErrorIcon = 'IconType.ToastErrorIcon',
    ToastInfoIcon = 'IconType.ToastInfoIcon',
    ToastSuccessIcon = 'IconType.ToastSuccessIcon',
    ToastUploadIcon = 'IconType.ToastUploadIcon',
    Umbrella = 'IconType.Umbrella',
    Upload = 'IconType.Upload',
    Unlock = 'IconType.Unlock',
    User = 'IconType.User',
    Users = 'IconType.Users',
    VideoOff = 'IconType.VideoOff',
    Video = 'IconType.Video',
    Volume0 = 'IconType.Volume0',
    Volume1 = 'IconType.Volume1',
    Volume2 = 'IconType.Volume2',
    VolumeOff = 'IconType.VolumeOff',
}

export enum IconMode {
    // TODO: according to @nnpl/caz-common-eslint-config, all enum members' names
    // should be PascalCased. However, this is quite debatable here in ui-library,
    // therefore it was disabled here on purpose.
    // See: https://gitlab.inspol.biz/CustomerZone/portal/ui-library/-/merge_requests/299#note_142580
    /* eslint-disable @typescript-eslint/naming-convention */
    default = 'IconMode.default',
    error = 'IconMode.error',
    disabled = 'IconMode.disabled',
    success = 'IconMode.success',
    /* eslint-enable @typescript-eslint/naming-convention */
}

export enum IconSize {
    // TODO: see note about IconMode
    /* eslint-disable @typescript-eslint/naming-convention */
    sm = 'IconSize.sm',
    md = 'IconSize.md',
    lg = 'IconSize.lg',
    xl = 'IconSize.xl',
    size12 = 'IconSize.12',
    size16 = 'IconSize.16',
    size24 = 'IconSize.24',
    size32 = 'IconSize.32',
    size48 = 'IconSize.48',
    size64 = 'IconSize.64',
    size120 = 'IconSize.120',
    /* eslint-enable @typescript-eslint/naming-convention */
}
