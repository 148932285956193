import AlertSmall from './alert-small.svg';
import ArrowDown from './arrow-down.svg';
import ArrowLeft from './arrow-left.svg';
import ArrowRight from './arrow-right.svg';
import Close from './close.svg';
import InfoSmall from './info-small.svg';
import Info from './info.svg';
import MoreVertical from './more-vertical.svg';
import OK from './ok.svg';
import Search from './search.svg';

export const icons = {
  'alert-small': AlertSmall,
  'arrow-down': ArrowDown,
  'arrow-left': ArrowLeft,
  'arrow-right': ArrowRight,
  close: Close,
  'info-small': InfoSmall,
  info: Info,
  'more-vertical': MoreVertical,
  ok: OK,
  search: Search,
} as const;
