import { useState } from 'react';
import { SVGButton } from '../Button';
import IconNotVisible from './icon-not-visible.svg';
import IconVisible from './icon-visible.svg';
import { Input } from '../Input';
import { InputProps } from '../Input/Input';
import { Pictogram } from '../Pictogram/Pictogram';

export const PasswordInput: React.FC<InputProps> = ({
  onChange,
  value,
  label,
  required,
  pattern,
  name,
  error,
  disabled,
  icon,
  onInValid,
  keyPattern,
  focusOnParent,
  onBlur,
  onFocus,
  onKeyDown,
  cursor,
  caption,
  dense,
  ...props
}) => {
  const [passwordVisibility, setPasswordVisibility] = useState<boolean>(false);
  return (
    <Input
      caption={caption}
      cursor={cursor}
      dense={dense}
      disabled={disabled}
      error={error}
      focusOnParent={focusOnParent}
      icon={passwordVisibility
        ? (
          <SVGButton onClick={() => setPasswordVisibility(false)} type="button">
            <Pictogram Icon={IconNotVisible} />
          </SVGButton>
        )
        : (
          <SVGButton onClick={() => setPasswordVisibility(true)} type="button">
            <Pictogram Icon={IconVisible} />
          </SVGButton>
        )}
      keyPattern={keyPattern}
      label={label}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      onInValid={onInValid}
      onKeyDown={onKeyDown}
      pattern={pattern}
      required={required}
      type={passwordVisibility ? 'text' : 'password'}
      value={value}
      {...props}// eslint-disable-line react/jsx-props-no-spreading
    />
  );
};
