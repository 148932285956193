import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export interface Props {
  children: Date;
}

export function formatDate(date: Date): string {
  return dayjs(date).format('DD.MM.YYYY');
}

export function formatPostDate(date: Date): string {
  return dayjs(date).format('YYYY-MM-DD');
}

export function parseStringToDate(dateFrontString: string, date?: Date): Date {
  const newDate = dayjs(dateFrontString, 'DD.MM.YYYY');
  return dayjs(date)
    .set('day', newDate.get('day'))
    .set('year', newDate.get('year'))
    .set('month', newDate.get('month'))
    .toDate();
}

export const FormatDate: React.FC<Props> = ({ children }: Props) => (
  <>{formatDate(children)}</>
);
