import styled, {
  keyframes,
  css,
  CSSProp,
  FlattenSimpleInterpolation,
} from 'styled-components';
import { useCallback, useEffect, useState } from 'react';
import { primaryColors } from '../../colors/colors';
import { Portal } from '../Portal/Portal';
import { SVGButton } from '../Button/SVGButton';
import { Typo } from '../Typo';
import { Pictogram } from '../Pictogram/Pictogram';

const withTranslation = {
  S: '25%',
  M: '50%',
  L: '75%',
  XL: '100%',
};

export interface DrawerProps {
  width?: keyof typeof withTranslation;
  header: string;
  onClose: () => void;
  children: (JSX.Element | React.ReactNode | string)[];
  closeOnBackdropClick?: boolean;
  footerType?: 'default' | 'stickyBottom' | 'plain';
  bodyOverrideStyle?: CSSProp<FlattenSimpleInterpolation>;
  domElement?: string;
  isOpen?: boolean;
}

const drawerOpenWrapperAnimation = keyframes`
  from {
    background: transparent;
    width: 100vw;
    height: 100vh;
  }
  to { background: rgba(0, 0, 0, 0.25); }
`;

const drawerCloseWrapperAnimation = keyframes`
  0% {
    background: rgba(0, 0, 0, 0.25);
    width: 100vw;
    height: 100vh;
  }
  99% {
    width: 100vw;
    height: 100vh;
    background: transparent
  }
  100% {
    width: 0;
    height: 0;
    background: transparent
  }
`;

const DrawerPortal = styled(Portal)`
  position: fixed;
`;

const DrawerWrapper = styled.div<{ animationType: 'open' | 'close' }>`
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  background: rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  ${({ animationType }) => (animationType === 'open'
    ? css`
          animation: ${drawerOpenWrapperAnimation} 275ms ease-in forwards;
        `
    : css`
          animation: ${drawerCloseWrapperAnimation} 275ms ease-in forwards;
        `)}
  backdrop-filter: blur(5px);
`;

type CssWidth = '25%' | '50%' | '75%' | '100%';

const animationBase = '275ms ease-in forwards';
const keyframeOpenBase = (width: string) => ({
  from: 'width: 0; opacity: 0;',
  to: `width: ${width}; opacity: 1`,
});

const keyframeCloseBase = (width: string) => ({
  from: `width: ${width}; opacity: 1`,
  to: 'width: 0; opacity: 0;',
});

const LengthAnimation = (width: string, animationType: string) => keyframes`
  from {${
  animationType === 'open'
    ? keyframeOpenBase(width).from
    : keyframeCloseBase(width).from
}}
  to {${
  animationType === 'open'
    ? keyframeOpenBase(width).to
    : keyframeCloseBase(width).to
}}
`;

const getAnimation = (width: CssWidth, animationType: string) => css`
  animation: ${() => LengthAnimation(width, animationType)} ${animationBase};
`;

const DrawerInner = styled.div<{
  $width: CssWidth;
  animationType: 'open' | 'close';
}>`
  background: white;
  ${({ $width, animationType }) => getAnimation($width, animationType)}
  @media (max-width: 1024px) {
    animation: ${({ animationType }) => getAnimation('100%', animationType)}
      275ms ease-in forwards;
  }
`;

const DrawerButtonClose = styled(SVGButton)`
  position: absolute;
  margin-right: 36px;
  right: 0;
`;

const DrawerHeader = styled.div`
  height: 80px;
  width: 100%;
  border-bottom: 1px solid ${primaryColors.greyWhite};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;

const drawerBodyAnimation = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const DrawerScroll = styled.div<{ plain: boolean }>`
  display: block;
  height: ${({ plain }) => (plain ? 'calc(100% - 80px)' : 'calc(100% - 160px)')};
  overflow: auto;
`;

const DrawerBody = styled.div<{
  bodyOverrideStyle?: CSSProp;
}>`
  opacity: 0;
  margin: 0 auto;
  padding: 0 1.5rem;
  max-width: 660px;

  @media (max-width: 1024px) {
    max-width: 546px;
  }

  box-sizing: border-box;
  overflow: auto;
  animation: ${drawerBodyAnimation} 275ms ease-in forwards;
  animation-delay: 275ms;
  ${({ bodyOverrideStyle }) => (bodyOverrideStyle || '')}
`;

const DrawerFooter = styled.div`
  border-top: 1px solid ${primaryColors.greyWhite};
  box-sizing: border-box;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0 1.5rem;
`;

const DrawerFooterPlain = styled.div`
  box-sizing: border-box;
  margin-bottom: 3rem;
  padding: 0 1.5rem;
  margin: 0 auto;
  max-width: 660px;

  @media (max-width: 1024px) {
    max-width: 546px;
  }
`;

const backdropButtonLength = (width: string) => 100 - Number(width.replace('%', ''));

const BackdropButton = styled.button<{ $width: string }>`
  padding: 0;
  border: none;
  width: ${({ $width }) => `${backdropButtonLength($width)}%`};
  @media (max-width: 1024px) {
    width: 0;
  }
  opacity: 0;
`;

export const Drawer: React.FC<DrawerProps> = ({
  header,
  onClose,
  children,
  width = 'M',
  closeOnBackdropClick,
  footerType = 'default',
  bodyOverrideStyle,
  domElement,
  isOpen,
}) => {
  const [itsRendered, setIsRendered] = useState<boolean>(false);
  const handleOnWrapperAnimationEnd = useCallback(
    () => (isOpen === false ? setIsRendered(false) : setIsRendered(true)),
    [isOpen],
  );

  const handleOnInnerAnimationEnd = useCallback(
    () => (isOpen ? onClose : undefined),
    [isOpen, onClose],
  );

  useEffect((): void => {
    if (isOpen === true || isOpen === undefined) {
      return setIsRendered(true);
    }
    return undefined;
  }, [isOpen]);

  if (!itsRendered) {
    return null;
  }
  return (
    <DrawerPortal domElement={domElement}>
      <DrawerWrapper
        animationType={isOpen === false ? 'close' : 'open'}
        onAnimationEnd={handleOnWrapperAnimationEnd}
      >
        {closeOnBackdropClick && (
          <BackdropButton
            $width={withTranslation[width] as CssWidth}
            onClick={onClose}
            type="button"
          />
        )}
        <DrawerInner
          $width={withTranslation[width] as CssWidth}
          animationType={isOpen === false ? 'close' : 'open'}
          onAnimationEnd={handleOnInnerAnimationEnd}
        >
          <DrawerHeader>
            <Typo $type="bodyBold" noMargin>
              {header}
            </Typo>
            <DrawerButtonClose onClick={onClose} type="button">
              <Pictogram icon="close" />
            </DrawerButtonClose>
          </DrawerHeader>
          <DrawerScroll plain={footerType === 'plain'}>
            <DrawerBody bodyOverrideStyle={bodyOverrideStyle}>
              {children[0]}
              {footerType === 'plain' && children[1]}
            </DrawerBody>
          </DrawerScroll>
          {footerType === 'stickyBottom' && (
            <DrawerFooterPlain>{children[1]}</DrawerFooterPlain>
          )}
          {footerType === 'default' && (
            <DrawerFooter>{children[1]}</DrawerFooter>
          )}
        </DrawerInner>
      </DrawerWrapper>
    </DrawerPortal>
  );
};
