import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { Sizes, ButtonBase } from './ButtonBase';

function getCSSforSize($size?: Sizes): FlattenSimpleInterpolation {
  if ($size === 'l') {
    return css`
      height: 48px;
      width: 48px;
    `;
  } if ($size === 'm') {
    return css`
      height: 32px;
      width: 32px;
    `;
  }

  return css`
    height: 16px;
    width: 16px;
  `;
}

export const ButtonIcon = styled(ButtonBase)`
  ${({ $size }) => getCSSforSize($size)};
  border-radius: 50%;
  padding: 0;
  align-items: center;
  justify-content: center;
  display: flex;
`;
