import { useCallback, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { primaryColors } from '../../../colors/colors';
import { SVGButton } from '../../Button';
import { MONTHS, MONTHS_SHORT } from '../utils/DateInputTranslations';
import { MarginsWrapper } from '../../Margins/Margins';
import { Pictogram } from '../../Pictogram/Pictogram';

const NavbarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 18px;
  box-sizing: border-box;
  padding: 0 16px;
`;

const MonthButton = styled.button`
  font-size: 1.25rem;
  line-height: 1;
  font-family: NNDagnyDisplay;
  background: transparent;
  border: none;
  box-shadow: none;
  outline: none;
  display: flex;
  align-items: center;
  font-weight: normal;
  padding: 0;
  padding-right: 11px;
  cursor: pointer;
`;

const NavbarDatePickerModal = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen && 'block') || 'none'};
  box-sizing: border-box;
  position: absolute;
  z-index: 10;
  left: 0;
  top: 31px;
  min-height: 200px;
  background: #fff;
  width: 100%;
  padding: 20px;
  height: calc(100% - 31px);
  border-radius: 3px;
`;

const NavbarChooseMonthWrapper = styled.div`
  border-top: 1px solid ${primaryColors.greyWhite};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 6px;
`;

const NavbarMonthButtonWrapper = styled.div`
  display: flex;
`;

const NavbarMonthButton = styled.button<{ isSelected: boolean }>`
  background: transparent;
  border: none;
  box-shadow: none;
  width: 56px;
  height: 32px;
  outline: none;
  margin: 0 12px;
  color: ${primaryColors.greyDark};
  border-radius: 3px;
  font-weight: normal;
  cursor: pointer;

  &:hover {
    border: 1px solid ${primaryColors.orangeMedium};
    box-shadow: 0px 5px 20px rgba(240, 128, 0, 0.148805);
  }

  ${({ isSelected }) => (isSelected
      && css`
        background-color: ${primaryColors.orangeMedium};
        color: #fff;
      `)
    || ''}
`;

const MonthArrowButton = styled(SVGButton)<{ isOpen: boolean }>`
  transition: transform 150ms;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

const NavbarButtonArrowsWrapper = styled.span`
  display: flex;
`;

interface Props {
  setSelectedMonth: (_: Date) => void;
  onPreviousClick: (callback?: (() => void) | undefined) => void;
  onNextClick: (callback?: (() => void) | undefined) => void;
  month: Date;
}

export const DateInputNavbar: React.FC<Props> = ({
  onPreviousClick,
  onNextClick,
  month,
  setSelectedMonth,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [year, setYear] = useState<number>(Number(month.getFullYear()));
  const selectedMonth = month.getUTCMonth();
  const navbarDatePickerModalRef = useRef<HTMLDivElement>(null);

  const onClickMonthDropdownButton = (
    e:
    | React.MouseEvent<HTMLButtonElement, MouseEvent>
    | React.MouseEvent<Element, MouseEvent>,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen((prevState) => !prevState);
  };

  const onClickNext = useCallback(() => {
    if (isOpen) {
      month.setFullYear(year + 1, selectedMonth);
      setSelectedMonth(month);
      setYear(year + 1);
    } else {
      onNextClick();
    }
  }, [isOpen, month, onNextClick, selectedMonth, setSelectedMonth, year]);

  const onPreviosClick = useCallback(() => {
    if (isOpen) {
      month.setFullYear(year - 1, selectedMonth);
      setSelectedMonth(month);
      setYear(year - 1);
    } else {
      onPreviousClick();
    }
  }, [isOpen, month, onPreviousClick, selectedMonth, setSelectedMonth, year]);

  const onClickMonthButton = useCallback(
    (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      newMonth: number,
    ): void => {
      e.preventDefault();
      month.setFullYear(year, newMonth);
      setSelectedMonth(month);
      setIsOpen(false);
    },
    [month, setSelectedMonth, year],
  );

  return (
    <NavbarWrapper>

      <NavbarMonthButtonWrapper>
        <MonthButton onClick={onClickMonthDropdownButton} type="button">
          {MONTHS[selectedMonth]}
          {' '}
          {month.getFullYear()}
        </MonthButton>
        <MonthArrowButton
          isOpen={isOpen}
          onClick={onClickMonthDropdownButton}
          type="button"
        >
          <Pictogram icon="arrow-down" />
        </MonthArrowButton>
      </NavbarMonthButtonWrapper>
      <NavbarButtonArrowsWrapper>
        <MarginsWrapper marginRight="M">
          <SVGButton onClick={onPreviosClick} type="button">
            <Pictogram icon="arrow-left" />
          </SVGButton>
        </MarginsWrapper>
        <SVGButton onClick={onClickNext} type="button">
          <Pictogram icon="arrow-right" />
        </SVGButton>
      </NavbarButtonArrowsWrapper>
      <NavbarDatePickerModal ref={navbarDatePickerModalRef} isOpen={isOpen}>
        <NavbarChooseMonthWrapper>
          {MONTHS_SHORT.map((monthShort: string, index) => (
            <NavbarMonthButton
              key={monthShort}
              isSelected={index === selectedMonth}
              onClick={(e) => onClickMonthButton(e, index)}
              type="button"
            >
              {monthShort}
            </NavbarMonthButton>
          ))}
        </NavbarChooseMonthWrapper>
      </NavbarDatePickerModal>
    </NavbarWrapper>
  );
};
