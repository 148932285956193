import React, { MouseEvent } from 'react';
import classNames from 'classnames';

import { IconMode, IconSize } from './Icon.types';
import { getAlternativeIconText, getIconSize } from './Icon.helpers';
import classes from './Icon.module.scss';
import { IconTypeAll } from './IconAll.set';
import { iconSet } from './IconSet';
import { pictographSet } from './PictographSet';
import { flagSet } from './FlagSet';

const iconSetAll = {
    ...iconSet,
    ...pictographSet,
    ...flagSet,
};

export interface IconProps {
    /** icon type enum that provides every supported icon name */
    type: IconTypeAll;
    /** css classes */
    className?: string;
    /** icon type enum that provides supported icon modes: default | error | success | disabled */
    mode?: IconMode;
    /** icon size enum that provides sizes as: sm | md | lg | xl */
    size?: IconSize;
    /** determines showing an oval background behind the icon */
    isCircular?: boolean;
    /** icon alternative text */
    title?: string;
    /** icon mouse click handler */
    onClick?: (event: MouseEvent) => void;
}

export const Icon: React.FC<IconProps> = (props: IconProps) => {
    const {
        className,
        type,
        title,
        mode,
        size = IconSize.md,
        isCircular,
        onClick: handleOnClick,
    } = props;

    const iconClassNames = classNames(
        'nn-icon',
        classes.icon,
        {
            [classes.error]: mode === IconMode.error,
            [classes.success]: mode === IconMode.success,
            [classes.disabled]: mode === IconMode.disabled,
            [classes.sizeSm]: size === IconSize.sm,
            [classes.sizeMd]: size === IconSize.md,
            [classes.sizeLg]: size === IconSize.lg,
            [classes.sizeXl]: size === IconSize.xl,

            [classes.size12]: size === IconSize.size12,
            [classes.size16]: size === IconSize.size16,
            [classes.size24]: size === IconSize.size24,
            [classes.size32]: size === IconSize.size32,
            [classes.size48]: size === IconSize.size48,
            [classes.size64]: size === IconSize.size64,
            [classes.size120]: size === IconSize.size120,
            [classes.circular]: isCircular,
        },
        className,
    );

    const IconComponent = iconSetAll[type];

    const iconSize = getIconSize({
        size,
        isCircular,
    });

    return (
        // TODO: Fix a11y here, by adding proper role, focus & keyboard event handling
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
        <div
            onClick={handleOnClick}
            className={iconClassNames}
        >
            <IconComponent
                title={title ?? getAlternativeIconText(type)}
                width={iconSize}
                height={iconSize}
            />
        </div>
    );
};
