import styled, { css } from 'styled-components';
import { nnColors } from '../../colors';
import { ErrorField } from '../Form/ErrorField';
import IconOK from './icon-ok.svg';
import { MarginsWrapper } from '../Margins';
import { Typo } from '../Typo';

interface CheckboxViewProps {
  disabled?: boolean;
  error?: boolean;
}

const CheckboxView = styled.input<CheckboxViewProps>`
  appearance: none;
  width: 24px;
  height: 24px;
  max-width: 24px;
  max-height: 24px;
  border: 1px solid;
  background: white;
  margin: 0;
  border-radius: 4px;
  flex-shrink: 0;
  cursor: pointer;
  ${({ error }) => error
    && css`
      border-color: ${nnColors.red};
      background: white;
      cursor: pointer;
    `}
  ${({ disabled }) => (disabled
    ? css`
          border-color: ${nnColors.greyWhite};
          background: ${nnColors.greyWhite};
          cursor: not-allowed;
        `
    : css`
          border-color: ${nnColors.greyLight};
          background: white;
          cursor: pointer;
        `)}
`;

const CheckboxWrapper = styled.div`
  position: relative;
  height: 24px;
`;

const Label = styled.label<{ disabled?: boolean }>`
  display: flex;
  ${({ disabled }) => (disabled
    ? css`
          cursor: not-allowed;
        `
    : css`
          cursor: pointer;
        `)}
`;

const CheckboxTick = styled(IconOK)<{ value: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  transition: opacity 275ms;
  ${({ value }) => (value
    ? css`
          opacity: 1;
        `
    : css`
          opacity: 0;
        `)}
`;

const LabelText = styled(Typo)`
  margin-top: 3px;
`;

export interface CheckboxProps {
  value: boolean;
  onChange: (_: boolean) => void;
  disabled?: boolean;
  label?: string;
  error?: string;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  value,
  label,
  disabled,
  error,
  onChange,
}) => (
  <span>
    <Label disabled={disabled}>
      <MarginsWrapper marginRight={label ? 'S' : undefined}>
        <CheckboxWrapper>
          <CheckboxView
            disabled={disabled}
            error={!!error}
            onChange={() => (disabled ? undefined : onChange(!value))}
            type="checkbox"
            value={`${value}`}
          />
          <CheckboxTick
            color={disabled ? nnColors.greyLight : undefined}
            value={value}
          />
        </CheckboxWrapper>
      </MarginsWrapper>
      {label && (
        <LabelText $type="body" noMargin>
          {label}
        </LabelText>
      )}
    </Label>
    <ErrorField error={error} />
  </span>
);
