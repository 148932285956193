import React from 'react';
import classNames from 'classnames';

import { Icon } from '~/components/Icon/Icon.component';
import { IconType, IconMode, IconSize } from '~/components/Icon/Icon.types';

import styles from './ErrorMessage.module.scss';

interface ErrorMessageProps {
    /**  Determine if "Alert" icon is displayed or not */
    hasIcon?: boolean;
}

export const ErrorMessage: React.FC<ErrorMessageProps> = (props) => {
    const { hasIcon = false, children } = props;

    return (
        <div
            className={classNames([ styles.errorMessage ])}
        >
            {hasIcon && (
                <Icon
                    className="mr-1"
                    type={IconType.Alert}
                    size={IconSize.sm}
                    mode={IconMode.error}
                    isCircular
                />
            )}

            <span>
                {children}
            </span>
        </div>
    );
};
