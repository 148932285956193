import { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { nnColors, primaryColors } from '../../colors/colors';
import { TabsPropsHook } from './hook/useTabs';
import { DropDownMenu, DropDownLinkOptionType } from './components/DropDownLinksMenu';
import { SVGButton } from '../Button/SVGButton';
import { MarginsWrapper } from '../Margins';
import { Pictogram } from '../Pictogram/Pictogram';

export type TabConfig = {
  icon?: JSX.Element;
  to?: string;
  name: string;
  active?: boolean;
  onClose?: () => void;
  onClick?: () => void;
  disabled?: () => void;
};

// add transition -> animation go down / animation go up

const PrimaryTabsWrapper = styled.div`
  border-bottom: 1px solid ${primaryColors.greyLight};
  position: relative;
`;

const TabsMenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TabsWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  width: fit-content;
`;

const Label = styled.p`
  font-family: NNDagnyDisplay;
  font-size: 20px;
  line-height: 28px;
  color: ${primaryColors.greyDark};
  transition: 150ms color ease-in-out;
`;

const Tab = styled(Link)<{ active: boolean }>`
  height: 49px;
  background: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${primaryColors.orangeMedium};
  cursor: pointer;
  text-decoration: none;

  ${({ active }) => (active
    ? css`
        position: relative;
        color: ${primaryColors.orangeMedium};
        &::after {
          content: '';
          position: absolute;
          width: 100%;
          bottom: -1px;
          height: 3px;
          background-color: ${primaryColors.orangeMedium};
        }
      ` : css`
          &:hover {
            ${Label} {
              color: ${nnColors.orangeMedium}
            }
          }
      `)}
`;

const IconWrapper = styled.div`
  margin-right: 17.33px;
`;

const CloseTabButton = styled(SVGButton)`
  margin-left: 12px;
`;

const dropdownOptions = (
  tabs: TabConfig[],
  onCloseMenuOption: (name: string, onClose?: () => void) => void,
): DropDownLinkOptionType[] => tabs.map((tab) => ({
  onClose: () => onCloseMenuOption(tab.name, tab.onClose),
  onClick: tab.onClick,
  to: tab.to,
  name: tab.name,
}));

export interface PrimaryTabsProps {
  tabs: TabsPropsHook['tabs'],
  menuOptions?: TabsPropsHook['menuOptions'],
  onCloseTab?: TabsPropsHook['onCloseTab'],
  onClickTab?: TabsPropsHook['setActivetab'],
  onCloseMenuOption?: TabsPropsHook['onCloseMenuOption'],
  menuOptionInit?: TabsPropsHook['menuOptionInit'],
}

export const PrimaryTabs = ({
  tabs,
  menuOptions,
  onCloseTab,
  onClickTab,
  onCloseMenuOption,
  menuOptionInit,
}: PrimaryTabsProps): JSX.Element => {
  const primaryTabsWrapperRef = useRef<HTMLDivElement | null>(null);
  const tabsWrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const primaryTabsClientWidth = primaryTabsWrapperRef.current?.clientWidth || 0;
    const tabsClientWidth = tabsWrapperRef.current?.clientWidth || 0;
    const cuttingPlace = tabs.length - 1;
    if (tabsClientWidth > primaryTabsClientWidth - 2 && menuOptionInit) {
      menuOptionInit(cuttingPlace);
    }
  }, [primaryTabsWrapperRef, tabsWrapperRef, menuOptionInit, tabs.length]);

  const handleOnClickClose = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    name: string,
    onClose?: () => void,
  ) => {
    e.stopPropagation();
    e.preventDefault();
    if (onCloseTab) {
      onCloseTab(name);
    }
    if (onClose) {
      onClose();
    }
  };

  const handleOnClickCloseOption = (
    name: string,
    onClose?: () => void,
  ): void => {
    if (onCloseMenuOption) {
      onCloseMenuOption(name);
    }
    if (onClose) {
      onClose();
    }
  };

  const handleOnClick = (tab: TabConfig): void => {
    if (onClickTab) {
      onClickTab(tab.name);
    }
    if (tab.onClick) {
      tab.onClick();
    }
  };

  return (
    <PrimaryTabsWrapper ref={primaryTabsWrapperRef}>
      <TabsMenuWrapper>
        <TabsWrapper ref={tabsWrapperRef}>
          {tabs.map((tab) => (
            <MarginsWrapper key={tab.name} marginRight="L">
              <Tab
                active={!!tab.active}
                onClick={() => handleOnClick(tab)}
                to={tab.to || ''}
              >
                {tab.icon && IconWrapper}
                <Label>{tab.name}</Label>
                {tab.onClose && (
                <CloseTabButton
                  onClick={(e) => handleOnClickClose(e, tab.name, tab.onClose)}
                  type="button"
                >
                  <Pictogram icon="close" />
                </CloseTabButton>
                )}
              </Tab>
            </MarginsWrapper>
          ))}
        </TabsWrapper>
        {menuOptions && menuOptions.length ? (
          <DropDownMenu
            options={dropdownOptions(menuOptions, handleOnClickCloseOption)}
          />
        ) : undefined}
      </TabsMenuWrapper>
    </PrimaryTabsWrapper>
  );
};
