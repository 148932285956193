import React, { useCallback } from 'react';
import classnames from 'classnames';
import { toast, Slide, ToastOptions } from 'react-toastify';

import { IconType } from '~/components/Icon';

import { getToastDuration, getToastIconType } from '../Toast.helpers';
import classes from '../Toast.module.scss';
import { ToastContent } from '../ToastContent.component';
import { toastBasePosition } from '../Toast.constants';
import { ToastVariant } from '../Toast.types';

export type UseToastProps = ToastOptions & {
    /** toast message */
    message: string;
    /** Optional toast variant
     * @default ToastVariant.INFO
     */
    variant?: ToastVariant;
    /** Optional toast styles */
    className?: string;
    /** Optional icon type. By default it is related to variant */
    iconType?: IconType;
};

export interface UseToastReturn {
    /** Function used to invoking Toast as a handler with optional attributes */
    playToast: (options?: Partial<UseToastProps>) => ReturnType<typeof toast>;
    toast: typeof toast;
}

export const useToast = (config: UseToastProps): UseToastReturn => {
    const {
        message,
        variant = ToastVariant.INFO,
        className,
        autoClose,
        iconType,
        ...restProps
    } = config;

    const showToast = useCallback((options?: Partial<UseToastProps>) => {
        const messageToShow = options?.message ?? message;
        const variantToShow = options?.variant ?? variant;
        const iconTypeToShow = iconType ?? getToastIconType(variantToShow);
        const toastDuration = autoClose ?? getToastDuration(messageToShow);

        const toastStyles = classnames(
            {
                [classes.toastSuccess]: variantToShow === ToastVariant.SUCCESS,
                [classes.toastError]: variantToShow === ToastVariant.ERROR,
                [classes.toastAlert]: variantToShow === ToastVariant.ALERT,
                [classes.toastInfo]: variantToShow === ToastVariant.INFO,
            },
            classes.toast,
            className,
        );

        const toastProps: ToastOptions = {
            transition: Slide,
            className: toastStyles,
            position: toastBasePosition,
            bodyClassName: classes.toastContentBody,
            hideProgressBar: true,
            pauseOnHover: true,
            closeButton: false,
            autoClose: toastDuration,
            ...restProps,
            ...options,
        };

        return toast(
            <ToastContent
                iconType={iconTypeToShow}
                message={messageToShow}
            />, toastProps,
        );
    }, [
        variant,
        className,
        message,
        restProps,
        autoClose,
        iconType,
    ]);

    return {
        playToast: showToast,
        toast,
    };
};
