import {
  ReactNode, useCallback, useEffect, useState,
} from 'react';
import styled, { css } from 'styled-components';
import { primaryColors, secondarySuplement } from '../../colors/colors';
import { ElementFormWrapper } from '../Form/ElementFormWrapper';

export interface InputProps
  extends Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'disabled' | 'name' | 'onChange'
  > {
  onChange: (_: string) => void;
  onInValid?: (_: boolean) => void;
  value?: string;
  label?: string;
  required?: boolean;
  pattern?: string;
  error?: string;
  caption?: string;
  name?: string;
  disabled?: boolean;
  keyPattern?: string;
  prefix?: string;
  icon?: ReactNode | JSX.Element;
  props?: HTMLInputElement;
  onBlur?: () => void;
  onFocus?: () => void;
  focusOnParent?: boolean;
  cursor?: string;
  dense?: boolean;
  onKeyDown?: (e?: React.KeyboardEvent) => void;
}

interface InputValidationProps {
  disabled?: boolean;
  showInvalid?: boolean;
  value?: string;
  focusOnParent?: boolean;
  cursor?: string;
  dense?: boolean;
  prefix?: string;
}

const InputView = styled.input<InputValidationProps>`
  border: 1px solid ${primaryColors.greyLight};
  color: ${primaryColors.greyDark};
  background: white;
  border-radius: 3px;
  box-sizing: border-box;
  outline: none;
  padding: 30px 44px 10px ${({ prefix }) => (prefix ? `${(prefix.length * 9) + 16}px` : '16px')};
  font-family: NNDagnyText;
  font-size: 16px;
  margin-bottom: 22px;
  width: 100%;
  cursor: ${({ cursor }) => cursor || 'text'};

  &::-ms-reveal,
  &::-ms-clear {
    display: none;
  }

  ${({ dense, prefix }) => (dense
    ? css`
    height: 48px;
    padding: 14px 44px 10px ${prefix ? `${(prefix.length * 9) + 16}px` : '16px'};
    `
    : css`
    height: 60px;
    padding: 30px 44px 10px ${prefix ? `${(prefix.length * 9) + 16}px` : '16px'};
    `)}

  ${({ focusOnParent }) => (focusOnParent
    ? css`
          border-color: ${primaryColors.orangeMedium};
          box-shadow: 0px 5px 20px rgba(240, 128, 0, 0.148805);
        `
    : '')}

  ${({ disabled }) => (disabled
    ? css`
          border-color: ${primaryColors.greyLight};
          color: ${primaryColors.greyLight};
          background: ${primaryColors.snowWhite};
          cursor: not-allowed;
        `
    : css`
          &:focus,
          &:hover {
            border-color: ${primaryColors.orangeMedium};
            box-shadow: 0px 5px 20px rgba(240, 128, 0, 0.148805);
          }
        `)}

  ${({ showInvalid }) => (showInvalid
      && css`
        caret-color: ${secondarySuplement.red};
        border-color: ${secondarySuplement.red};
        &::placeholder {
          color: ${secondarySuplement.red};
        }
        &:focus {
          border-color: ${secondarySuplement.red};
          box-shadow: none;
        }
      `)
    || css``}
`;

const Prefix = styled.span<InputValidationProps>`
  &::before {
    content: '${({ prefix }) => prefix}';
    position: absolute;
    z-index: 1;
    font-family: NNDagnyText;
    font-size: 16px;
    margin-bottom: 22px;
    cursor: text;
    padding: 30px 0 10px 16px;
    color: ${({ disabled }) => disabled && primaryColors.greyLight};
  }
`;

const InputElementFormWrapper = styled(ElementFormWrapper)<{
  disabled?: boolean;
}>`
  &:hover {
    input {
      ${({ disabled }) => !disabled
        && css`
          border-color: ${primaryColors.orangeMedium};
          box-shadow: 0px 5px 20px rgba(240, 128, 0, 0.148805);
        `}
    }
  }
`;

const isSpecialChar = (key: string | undefined) => ['Backspace', 'Meta', 'Alt', 'Control', 'Enter'].includes(key || '');

export const Input: React.FC<InputProps> = ({
  onChange,
  value,
  label,
  required,
  pattern,
  name,
  error,
  caption,
  prefix,
  disabled,
  icon,
  onInValid,
  keyPattern,
  focusOnParent,
  onBlur,
  onFocus,
  onKeyDown,
  cursor,
  dense,
  ...props
}) => {
  const [focused, setIsFocused] = useState<boolean>(false);
  const [blurred, setIsBlurred] = useState<boolean>(false);
  const [prevKey, setPrevKey] = useState('');

  const handleOnKeyDown = useCallback(
    (e?: React.KeyboardEvent<HTMLInputElement>) => {
      if (onKeyDown && !isSpecialChar(e?.key)) {
        onKeyDown(e);
      }
    },
    [onKeyDown],
  );
  const handleOnKeyDownPattern = useCallback(
    (e?: React.KeyboardEvent): void => {
      const keyRegExp = new RegExp(keyPattern || '');
      if (onKeyDown && !isSpecialChar(e?.key)) {
        onKeyDown(e);
      }
      const copyPasteAction = ['Meta', 'Control'].includes(prevKey)
        && ['c', 'v'].includes(e?.key || '');
      if (!keyRegExp.test(e?.key || '')
        && !isSpecialChar(e?.key)
        && !copyPasteAction) {
        e?.preventDefault();
      }

      setPrevKey(e?.key || '');
    },
    [keyPattern, prevKey, onKeyDown],
  );

  // invalid
  let isInvalid = false;
  let isInvalidAndBlurred = false;

  if (required && !value) { // when required blurred and not filled
    isInvalid = true;
  } else if (pattern && value && !(new RegExp(pattern || '').test(value))) { // has pattern but value didnt pass the test
    isInvalid = true;
  } else if (error) { // when error from parent is given
    isInvalid = true;
  }

  if (blurred) {
    isInvalidAndBlurred = isInvalid;
  }

  useEffect(
    () => onInValid && onInValid(isInvalid),
    [isInvalid, onInValid],
  );

  const handleOnBlur = useCallback(() => {
    if (onBlur) { onBlur(); }
    setIsFocused(false);
    setIsBlurred(true);
  }, [onBlur]);

  const handleOnFocus = useCallback(() => {
    if (onFocus) { onFocus(); }
    setIsFocused(true);
  }, [onFocus]);

  return (
    <InputElementFormWrapper
      caption={caption}
      cursor={cursor}
      dense={dense}
      disabled={disabled}
      error={error}
      filledUp={!(focused || focusOnParent || !!value)}
      icon={icon}
      label={label}
      required={required}
      showInvalid={isInvalidAndBlurred}
    >
      {prefix && (focused || value) && <Prefix disabled={disabled} prefix={prefix} />}
      <InputView
        cursor={cursor}
        dense={dense}
        disabled={disabled}
        focusOnParent={focusOnParent}
        name={name}
        onBlur={handleOnBlur}
        onChange={(e) => onChange(e.target.value)}
        onFocus={handleOnFocus}
        onKeyDown={(e) => (keyPattern ? handleOnKeyDownPattern(e) : handleOnKeyDown(e))}
        pattern={pattern}
        placeholder={dense ? label : undefined}
        prefix={prefix}
        required={required}
        showInvalid={isInvalidAndBlurred}
        value={value || ''}
        {...props} // eslint-disable-line react/jsx-props-no-spreading
      />
    </InputElementFormWrapper>
  );
};
