import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import styled, { css, CSSProp } from 'styled-components';
import { PaddingsProps, PaddingsWrapper } from '../Margins/Margins';

export interface GridProps extends React.HTMLAttributes<HTMLDivElement>, PaddingsProps {
  block?: boolean;
  vertical?: boolean;
  center?: boolean;
  align?: boolean;
  wrap?: boolean;
  flex?: number;
}

export const Grid = styled(PaddingsWrapper.withComponent('div')) <GridProps>`
  box-sizing: border-box;
  display: ${({ block }: GridProps): CSSProp => (block ? 'block' : 'flex')};
  flex: ${({ flex }: GridProps): number => (flex || flex === 0 ? flex : 1)};
  flex-direction: ${({ vertical }: GridProps): CSSProp => (vertical ? 'column' : '')};
  justify-content: ${({ center }: GridProps): CSSProp => (center ? 'center' : '')};
  align-items: ${({ align }: GridProps): CSSProp => (align ? 'center' : '')};

  ${({ wrap }: GridProps): CSSProp => (wrap && css`
    flex-wrap: wrap;
    align-items: flex-start;
  `) || ''};
`;
