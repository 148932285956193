import { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { nnColors } from '../../../colors';
import { SVGButton } from '../../Button/SVGButton';
import { Pictogram } from '../../Pictogram/Pictogram';
import { getTypoStyle } from '../../Typo';

export type DropDownLinkOptionType = {
  onClose?: () => void;
  onClick?: () => void;
  to?: string;
  name: string;
};

export interface DropDownMenuProps {
  options: DropDownLinkOptionType[];
}

const DropDownMenuWrapper = styled.div`
  position: relative;
`;

const DropDownMenuButton = styled(Link)`
  width: 100%;
  height: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${nnColors.greyDark};
  ${getTypoStyle({ $type: 'bodyBold' })}
  margin: 0;
`;

const DropDownDialog = styled.div`
  position: absolute;
  top: 24px;
  left: 0;
  padding-top: 15px;
  background: white;
  box-shadow: 0px 0px 4px rgba(65, 65, 65, 0.1),
    0px 8px 16px rgba(65, 65, 65, 0.1), 0px 16px 32px rgba(65, 65, 65, 0.2);
  border-radius: 3px;
  min-width: 378px;
`;

const DropDownOption = styled.div`
  height: 48px;
  margin: 0 16px;
  display: flex;
  align-items: center;
`;

export const DropDownMenu = ({ options }: DropDownMenuProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <DropDownMenuWrapper>
      <SVGButton onClick={(): void => setIsOpen(!isOpen)} type="button">
        <Pictogram icon="more-vertical" />
      </SVGButton>
      {isOpen && (
      <DropDownDialog>
        <>
          {options.map((option) => {
            const { onClick, onClose, to } = option;
            return (
              <DropDownOption key={option.name}>
                <DropDownMenuButton
                  onClick={onClick}
                  to={to || ''}
                  type="button"
                >
                  {option.name}
                </DropDownMenuButton>
                <SVGButton onClick={onClose} type="button">
                  <Pictogram icon="close" />
                </SVGButton>
              </DropDownOption>
            );
          })}
        </>
      </DropDownDialog>
      )}
    </DropDownMenuWrapper>
  );
};
