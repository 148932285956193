// eslint-disable-next-line import/no-extraneous-dependencies
import styled, { css } from 'styled-components';
import { nnColors } from '../../colors';
import { SVGButton } from '../Button';
import { Pictogram } from '../Pictogram/Pictogram';
import { Portal } from '../Portal';

export const defaultPosition = {
  top: 0,
  left: 0,
  height: 0,
  width: 0,
};

interface PositionStylesProps {
  direction: 'left' | 'right' | 'top' | 'bottom';
  parentAxis: typeof defaultPosition;
  childrenAxis: typeof defaultPosition;
}

export const ButtonWrapper = styled.div``;

export const TooltipPictogram = styled(Pictogram)`
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
  svg {
    margin-left: 4px;
  }
`;

const portalPositionStyles = ({
  direction,
  parentAxis,
  childrenAxis,
}: PositionStylesProps) => css`
  ${direction === 'left'
  && css`
    top: ${`${parentAxis.top - childrenAxis.height / 2 + parentAxis.height / 2}px`};
    left: ${`${parentAxis.left - childrenAxis.width - 16}px`};
  `}
  ${direction === 'right'
  && css`
    top: ${`${parentAxis.top - childrenAxis.height / 2 + parentAxis.height / 2}px`};
    left: ${`${parentAxis.left + parentAxis.width + 16}px`};
  `}
  ${direction === 'bottom'
  && css`
    top: ${`${parentAxis.top + parentAxis.height + 15}px`};
    left: ${`${parentAxis.left - childrenAxis.width / 2 + 8}px`};
  `}
  ${direction === 'top'
  && css`
    top: ${`${parentAxis.top - childrenAxis.height - 20}px`};
    left: ${`${parentAxis.left - childrenAxis.width / 2 + 16}px`};
  `}
`;

const absolutePositionStyles = ({
  direction,
  parentAxis,
  childrenAxis,
}: PositionStylesProps) => css`
  ${direction === 'left'
  && css`
    top: ${`-${(childrenAxis.height / 2) + 16}px`};
    left: ${`-${childrenAxis.width + 16}px`};
  `}
  ${direction === 'right'
  && css`
    top: ${`-${(childrenAxis.height / 2) + 16}px`};
    left: ${`${childrenAxis.width + 16}px`};
  `}
  ${direction === 'bottom'
  && css`
    top: ${`${parentAxis.height}px`};
    left: ${`-${(childrenAxis.width / 2) - 4}px`};
  `}
  ${direction === 'top'
  && css`
    top: ${`-${childrenAxis.height + parentAxis.height + 16}px`};
    left: ${`-${(childrenAxis.width / 2) - 16}px`};
  `}
`;

export const TooltipAbsoluteWrapper = styled.div`position: relative;`;

export const TooltipPortal = styled(Portal) <PositionStylesProps>`
  ${({ direction, parentAxis, childrenAxis }) => css`
    ${portalPositionStyles({ direction, parentAxis, childrenAxis })}
  `}
`;

export const TooltipAbsolute = styled.div<PositionStylesProps>`
  position: absolute;
  z-index: 9999;
  ${({ direction, parentAxis, childrenAxis }) => css`
    ${absolutePositionStyles({ direction, parentAxis, childrenAxis })}
  `}
`;

export const TooltipInfoButton = styled(SVGButton)`
  height: 24px;
  width: 24px;
  border-radius: 100%;
  background-color: ${nnColors.greyLight};
`;

export const TooltipChildrenWrapper = styled.div<{
  direction: PositionStylesProps['direction'];
}>`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  background: white;
  filter: drop-shadow(0px 1px 4px rgba(65, 65, 65, 0.1));
  border-radius: 3px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  position: relative;
  ${({ direction }) => css`
    &:after {
      content: '';
      width: 0px;
      height: 0px;
      position: absolute;
    }
    ${direction === 'left'
    && css`
      &:after {
        border-top: 16px solid transparent;
        border-left: 16px solid white;
        right: -16px;
      }
    `}
    ${direction === 'right'
    && css`
      &:after {
        border-top: 16px solid transparent;
        border-right: 16px solid white;
        left: -16px;
      }
    `}
    ${direction === 'bottom'
    && css`
      &:after {
        border-bottom: 16px solid white;
        border-left: 16px solid transparent;
        top: -16px;
      }
    `}
    ${direction === 'top'
    && css`
      &:after {
        border-top: 16px solid white;
        border-right: 16px solid transparent;
        bottom: -16px;
      }
    `}
  `}
`;
