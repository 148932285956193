import React from 'react';
import classNames from 'classnames';

import classes from './Button.module.scss';

interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
    /**
     * The variant of the button, for example `primary` or `secondary`
     *
     * @default "primary"
     **/
    variant?: 'primary' | 'secondary' | 'tertiary';
    /**
     * The size of the button, for example `lg` or `sm`
     *
     * @default "lg"
     **/
    size?: 'sm' | 'md' | 'lg';
    /** Optional class names to appear on the container. */
    className?: string;
    /** Determines if the button is disabled or not.
     *
     * @default false
     * */
    disabled?: boolean;

    /** The type of button, for example `button`, `submit` or `reset`.
     *
     * @default "button"
     **/
    type?: 'button' | 'submit' | 'reset';

    /**
     * Custom renderer of the Button component, used to replace the usage of the native `<button>` element.
     * Receives all default props and computed styling of the default implementation.
     *
     * @type string | (props: React.PropsWithChildren<ButtonProps>) => React.ReactElement
     **/
    as?: (string | ((props: React.PropsWithChildren<ButtonProps>) => React.ReactElement));
}

const wrapEventCallback = function<T>(isDisabled: boolean, callback?: T) {
    if (isDisabled) {
        return;
    }

    return callback;
};

export const Button: React.FC<ButtonProps> = (props) => {
    const { className, disabled = false, type = 'button', variant = 'primary', size = 'lg', onClick, ...restProps } = props;
    const btnClass = classNames(
        {
            [classes.btn]: true,
            [classes[`btn-${variant}`]]: variant,
            [classes[`btn-${size}`]]: size,
        },
        className,
    );

    const finalElementProps: ButtonProps = {
        type,
        className: btnClass,
        disabled,
        onClick: wrapEventCallback(disabled, onClick),
        ...restProps,
    };

    // Exception: React's custom components MUST start with an uppercase letter
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const Component = props.as ?? 'button';

    return (<Component {...finalElementProps} />);
};
