/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-extraneous-dependencies */
import styled from 'styled-components';
import ReactDOM from 'react-dom';
import { useEffect } from 'react';

const PortalWrapper = styled.div`
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
`;

export interface PortalProps {
  domElement?: string;
  isScrollEnabled?: boolean;
}

const getDOMElement = (element: string | undefined) => {
  if (element) {
    return document.getElementById(element);
  }
  const body = document.getElementsByTagName('body')[0];
  if (!document.getElementById('@K2Portal')) {
    body?.insertAdjacentHTML('beforeend', '<div id="@K2Portal"></div>');
  }
  return document.getElementById('@K2Portal');
};

export const Portal: React.FC<PortalProps> = ({
  domElement,
  children,
  isScrollEnabled,
  ...props
}) => {
  const domElementRoot = getDOMElement(domElement);
  useEffect((): void | (() => void) => {
    if (!isScrollEnabled) {
      document.body.style.overflow = 'hidden';
      return () => {
        document.body.style.overflow = 'visible';
      };
    }
    return undefined;
  }, [isScrollEnabled]);
  return domElementRoot ? (
    ReactDOM.createPortal(
      <PortalWrapper {...props}>{children}</PortalWrapper>,
      domElementRoot,
    )
  ) : null;
};
