import PL from '~/assets/flags/PL.svg';
import NE from '~/assets/flags/NE.svg';
import GB from '~/assets/flags/GB.svg';

import { FlagType } from './Flag.types';

export const flagSet = {
    [FlagType.PL]: PL,
    [FlagType.NE]: NE,
    [FlagType.GB]: GB,
};
