import { IconType } from '~/components/Icon';

import { minimumDuration, singleCharDuration } from './Toast.constants';
import { ToastVariant } from './Toast.types';

export const getToastDuration = (message: string): number => {
    if (!message) {
        return minimumDuration;
    }

    const { length } = message;
    const duration = minimumDuration + (length * singleCharDuration);

    if (duration < minimumDuration) {
        return minimumDuration;
    }

    return duration;
};

export const getToastIconType = (variant: ToastVariant): IconType => {
    switch (variant) {
        case ToastVariant.SUCCESS:
            return IconType.ToastSuccessIcon;
        case ToastVariant.ALERT:
            return IconType.ToastAlertIcon;
        case ToastVariant.INFO:
            return IconType.ToastInfoIcon;
        case ToastVariant.ERROR:
            return IconType.ToastErrorIcon;
    }
};
