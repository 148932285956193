/* eslint-disable import/no-extraneous-dependencies */
import styled, { css } from 'styled-components';
import { primaryColors } from '../../colors/colors';
import { Typo } from '../Typo/Typo';

export interface CollapseProps {
  icon?: JSX.Element | React.ReactNode;
  header: string | JSX.Element;
  onClick: () => void;
  rightSideArea?: JSX.Element | React.ReactNode;
  isOpen: boolean;
}

const Header = styled(Typo)`
  flex-shrink: 0;
  font-size: 28px;
  transition: color 150ms ease-in;
`;

const CollapseHeaderArea = styled.div`
  display: flex;
`;

const CollapseIcon = styled.div`
  margin-right: 22px;
  height: 36px;
  width: 36px;

  path {
    transition: fill 150ms ease-in, stroke 150ms ease-in;
  }
`;

const CollapseHeaderWrapper = styled.div`
  margin-left: 38px;
  display: flex;
  align-items: center;
`;

const CollapseExpandMark = styled.div<{ isOpen: boolean }>`
  height: 18px;
  width: 18px;
  margin-right: 35px;
  margin-left: 15px;
  box-sizing: border-box;
  border: 2px solid ${primaryColors.orangeMedium};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  transition: border-color 150ms ease-in;

  &:after {
    content: '';
    width: 10px;
    height: 2px;
    border-radius: 1px;
    background: ${primaryColors.orangeMedium};
    transition: color 150ms ease-in;
  }

  &:before {
    content: '';
    position: absolute;
    width: 10px;
    height: 2px;
    border-radius: 1px;
    background: ${primaryColors.orangeMedium};
    transform: rotate(90deg);
    transition: transform 500ms ease-in, color 150ms ease-in;
  }

  ${({ isOpen }) => (isOpen
      && css`
        &:before {
          transform: rotate(180deg);
        }
      `)
    || ''}
`;

const CollapseArea = styled.button`
  width: 100%;
  height: 91px;
  padding: 0;
  background: transparent;
  border: none;
  border-bottom: 1px solid ${primaryColors.greyWhite};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &:hover {
    ${Header} {
      color: ${primaryColors.orangeMedium};
    }
    path {
      fill: ${primaryColors.orangeDark};
    }
    ${CollapseExpandMark} {
      border-color: ${primaryColors.orangeDark};
      &:after,
      &:before {
        background: ${primaryColors.orangeDark};
      }
    }
  }
`;

export const CollapseHeader = ({
  icon,
  header,
  onClick,
  isOpen,
  rightSideArea,
}: CollapseProps): JSX.Element => (
  <CollapseArea onClick={onClick}>
    <CollapseHeaderWrapper>
      {icon && <CollapseIcon>{icon}</CollapseIcon>}
      <Header $color="greyDark" $type="H3" noMargin>
        {header}
      </Header>
    </CollapseHeaderWrapper>
    <CollapseHeaderArea>
      {rightSideArea}
      <CollapseExpandMark isOpen={isOpen} />
    </CollapseHeaderArea>
  </CollapseArea>
);
