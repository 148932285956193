import AddRound from '~/assets/icons/add-round.svg';
import Alert from '~/assets/icons/alert.svg';
import ArrowDownBold from '~/assets/icons/arrow-down-bold.svg';
import ArrowDownFull from '~/assets/icons/arrow-down-full.svg';
import ArrowDown from '~/assets/icons/arrow-down.svg';
import ArrowLeftBold from '~/assets/icons/arrow-left-bold.svg';
import ArrowLeft from '~/assets/icons/arrow-left.svg';
import ArrowRightBold from '~/assets/icons/arrow-right-bold.svg';
import ArrowRight from '~/assets/icons/arrow-right.svg';
import ArrowUpBold from '~/assets/icons/arrow-up-bold.svg';
import ArrowUpFull from '~/assets/icons/arrow-up-full.svg';
import ArrowUp from '~/assets/icons/arrow-up.svg';
import Bell from '~/assets/icons/bell.svg';
import Calendar from '~/assets/icons/calendar.svg';
import Camera from '~/assets/icons/camera.svg';
import Chart from '~/assets/icons/chart.svg';
import Chat from '~/assets/icons/chat.svg';
import Clock from '~/assets/icons/clock.svg';
import Close from '~/assets/icons/close.svg';
import Communication from '~/assets/icons/communication.svg';
import Copy from '~/assets/icons/copy.svg';
import Desktop from '~/assets/icons/desktop.svg';
import Document from '~/assets/icons/document.svg';
import Download from '~/assets/icons/download.svg';
import Edit from '~/assets/icons/edit.svg';
import Exit from '~/assets/icons/exit.svg';
import EyeOff from '~/assets/icons/eye-off.svg';
import Eye from '~/assets/icons/eye.svg';
import Fingerprint from '~/assets/icons/fingerprint.svg';
import Folder from '~/assets/icons/folder.svg';
import Gear from '~/assets/icons/gear.svg';
import Graph from '~/assets/icons/graph.svg';
import Heart from '~/assets/icons/heart.svg';
import Home from '~/assets/icons/home.svg';
import Info from '~/assets/icons/info.svg';
import InfoRound from '~/assets/icons/infoRound.svg';
import Link from '~/assets/icons/link.svg';
import Loader from '~/assets/icons/loader.svg';
import Lock from '~/assets/icons/lock.svg';
import Mail from '~/assets/icons/mail.svg';
import Maximize from '~/assets/icons/maximize.svg';
import Menu from '~/assets/icons/menu.svg';
import MicrophoneOff from '~/assets/icons/microphone-off.svg';
import Microphone from '~/assets/icons/microphone.svg';
import Minimize from '~/assets/icons/minimize.svg';
import Minus from '~/assets/icons/minus.svg';
import MinusShort from '~/assets/icons/minus-short.svg';
import Mobile from '~/assets/icons/mobile.svg';
import MoreVertical from '~/assets/icons/more-vertical.svg';
import More from '~/assets/icons/more.svg';
import Ok from '~/assets/icons/ok.svg';
import PhoneOff from '~/assets/icons/phone-off.svg';
import Phone from '~/assets/icons/phone.svg';
import Pin from '~/assets/icons/pin.svg';
import Play from '~/assets/icons/play.svg';
import Plus from '~/assets/icons/plus.svg';
import Printer from '~/assets/icons/printer.svg';
import Reload from '~/assets/icons/reload.svg';
import Search from '~/assets/icons/search.svg';
import Send from '~/assets/icons/send.svg';
import Share from '~/assets/icons/share.svg';
import Shield from '~/assets/icons/shield.svg';
import ShoppingCart from '~/assets/icons/shopping-cart.svg';
import SidesBold from '~/assets/icons/sides-bold.svg';
import Sides from '~/assets/icons/sides.svg';
import Signature from '~/assets/icons/signature.svg';
import Sliders from '~/assets/icons/slides.svg';
import SubtractRound from '~/assets/icons/subtract-round.svg';
import Star from '~/assets/icons/star.svg';
import Trash from '~/assets/icons/trash.svg';
import ToastAlertIcon from '~/assets/icons/ToastAlertIcon.svg';
import ToastErrorIcon from '~/assets/icons/ToastErrorIcon.svg';
import ToastInfoIcon from '~/assets/icons/ToastInfoIcon.svg';
import ToastSuccessIcon from '~/assets/icons/ToastSuccessIcon.svg';
import ToastUploadIcon from '~/assets/icons/ToastUploadIcon.svg';
import Umbrella from '~/assets/icons/umbrella.svg';
import Unlock from '~/assets/icons/unlock.svg';
import Upload from '~/assets/icons/upload.svg';
import User from '~/assets/icons/user.svg';
import Users from '~/assets/icons/users.svg';
import VideoOff from '~/assets/icons/video-off.svg';
import Video from '~/assets/icons/video.svg';
import Volume0 from '~/assets/icons/volume-0.svg';
import Volume1 from '~/assets/icons/volume-1.svg';
import Volume2 from '~/assets/icons/volume-2.svg';
import VolumeOff from '~/assets/icons/volume-off.svg';

import { IconType } from './Icon.types';

export const iconSet = {
    [IconType.AddRound]: AddRound,
    [IconType.Alert]: Alert,
    [IconType.ArrowDownBold]: ArrowDownBold,
    [IconType.ArrowDownFull]: ArrowDownFull,
    [IconType.ArrowDown]: ArrowDown,
    [IconType.ArrowLeftBold]: ArrowLeftBold,
    [IconType.ArrowLeft]: ArrowLeft,
    [IconType.ArrowRightBold]: ArrowRightBold,
    [IconType.ArrowRight]: ArrowRight,
    [IconType.ArrowUpBold]: ArrowUpBold,
    [IconType.ArrowUpFull]: ArrowUpFull,
    [IconType.ArrowUp]: ArrowUp,
    [IconType.Bell]: Bell,
    [IconType.Calendar]: Calendar,
    [IconType.Camera]: Camera,
    [IconType.Chart]: Chart,
    [IconType.Chat]: Chat,
    [IconType.Clock]: Clock,
    [IconType.Close]: Close,
    [IconType.Communication]: Communication,
    [IconType.Copy]: Copy,
    [IconType.Document]: Document,
    [IconType.Download]: Download,
    [IconType.Desktop]: Desktop,
    [IconType.Edit]: Edit,
    [IconType.Exit]: Exit,
    [IconType.EyeOff]: EyeOff,
    [IconType.Eye]: Eye,
    [IconType.Fingerprint]: Fingerprint,
    [IconType.Folder]: Folder,
    [IconType.Gear]: Gear,
    [IconType.Graph]: Graph,
    [IconType.Heart]: Heart,
    [IconType.Home]: Home,
    [IconType.Info]: Info,
    [IconType.InfoRound]: InfoRound,
    [IconType.Link]: Link,
    [IconType.Loader]: Loader,
    [IconType.Lock]: Lock,
    [IconType.Mail]: Mail,
    [IconType.Maximize]: Maximize,
    [IconType.MicrophoneOff]: MicrophoneOff,
    [IconType.Microphone]: Microphone,
    [IconType.Minimize]: Minimize,
    [IconType.Minus]: Minus,
    [IconType.MinusShort]: MinusShort,
    [IconType.Menu]: Menu,
    [IconType.Mobile]: Mobile,
    [IconType.MoreVertical]: MoreVertical,
    [IconType.More]: More,
    [IconType.Ok]: Ok,
    [IconType.Phone]: Phone,
    [IconType.PhoneOff]: PhoneOff,
    [IconType.Pin]: Pin,
    [IconType.Play]: Play,
    [IconType.Plus]: Plus,
    [IconType.Printer]: Printer,
    [IconType.Reload]: Reload,
    [IconType.Search]: Search,
    [IconType.Send]: Send,
    [IconType.Share]: Share,
    [IconType.Shield]: Shield,
    [IconType.ShoppingCart]: ShoppingCart,
    [IconType.SidesBold]: SidesBold,
    [IconType.Sides]: Sides,
    [IconType.Signature]: Signature,
    [IconType.Sliders]: Sliders,
    [IconType.SubrtactRound]: SubtractRound,
    [IconType.Star]: Star,
    [IconType.Trash]: Trash,
    [IconType.ToastAlertIcon]: ToastAlertIcon,
    [IconType.ToastErrorIcon]: ToastErrorIcon,
    [IconType.ToastInfoIcon]: ToastInfoIcon,
    [IconType.ToastSuccessIcon]: ToastSuccessIcon,
    [IconType.ToastUploadIcon]: ToastUploadIcon,
    [IconType.Umbrella]: Umbrella,
    [IconType.Unlock]: Unlock,
    [IconType.Upload]: Upload,
    [IconType.User]: User,
    [IconType.Users]: Users,
    [IconType.VideoOff]: VideoOff,
    [IconType.Video]: Video,
    [IconType.Volume0]: Volume0,
    [IconType.Volume1]: Volume1,
    [IconType.Volume2]: Volume2,
    [IconType.VolumeOff]: VolumeOff,
};
