/* eslint-disable import/no-extraneous-dependencies */
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export interface Props {
  children: string;
}

export function formatPhone(phone: string): string {
  let arrayOfChart = phone.split('').reverse().join('').replace(/(\d{3})/g, '$1 ');
  arrayOfChart = arrayOfChart.split('').reverse().join('').trim();
  return arrayOfChart;
}

export const FormatPhone: React.FC<Props> = ({
  children,
}: Props) => <>{formatPhone(children)}</>;
