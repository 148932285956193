import styled, { css } from 'styled-components';
import { MarginsWrapper } from '../Margins';
import { Typo } from '../Typo';
import { Pictogram } from '../Pictogram/Pictogram';

const CaptionFieldWrapper = styled.span<{ caption: boolean }>`
  align-items: center;
  margin-top: 6px;
  ${({ caption }) => (caption
      && css`
        display: flex;
      `)
    || css`
      display: none;
    `}
`;

const CaptionPictogram = styled(Pictogram)`
  min-width: 14px;
  min-height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
`;

export const CaptionField: React.FC<{ caption?: string }> = ({
  caption,
  ...props
}) => (
  <CaptionFieldWrapper
    {...props} // eslint-disable-line react/jsx-props-no-spreading
    caption={!!caption}
  >
    <CaptionPictogram icon="info-small" />
    <MarginsWrapper marginLeft="XS">
      <Typo $color="greyMedium" $type="caption">{caption}</Typo>
    </MarginsWrapper>
  </CaptionFieldWrapper>
);
