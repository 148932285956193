import { useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { primaryColors } from '../../colors';
import { Pictogram } from '../Pictogram/Pictogram';

const onOpenListAnimation = keyframes`
  0% {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    padding: 0;
  }
  99% {
    max-height: 418px;
    padding: 8px 0 14px 0;
    overflow: hidden;
  }
  100% {
    overflow: auto;
    opacity: 1;
    max-height: 418px;
    padding: 8px 0 14px 0;
  }
`;

const onCloseListAnimation = keyframes`
  from {
    max-height: 418px;
    opacity: 1;
    padding: 8px 0 14px 0;
  }
  to {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    padding: 0;
  }
`;

interface SelectListProps {
  isOpen?: boolean;
  dense?: boolean;
}

const SelectList = styled.div<SelectListProps>`
  position: absolute;
  z-index: 1;
  width: 100%;
  opacity: 0;
  top: ${({ dense }) => (dense ? '48px' : '60px')};
  max-height: 0;
  background: #fff;
  box-sizing: border-box;
  box-shadow: 0px 0px 4px rgba(65, 65, 65, 0.1),
    0px 8px 16px rgba(65, 65, 65, 0.1), 0px 16px 32px rgba(65, 65, 65, 0.2);
  border-radius: 3px;
  ${({ isOpen }) => isOpen !== undefined
    && css`
      animation: ${() => (isOpen ? onOpenListAnimation : onCloseListAnimation)}
        500ms forwards;
    `}
`;

interface ListButtonProps {
  selected: boolean;
  display: 'none' | 'block';
}

const ListButton = styled.button<ListButtonProps>`
  font-family: NNDagnyText;
  font-size: 16px;
  height: 48px;
  width: 100%;
  text-align: left;
  padding: 0 16px;
  background: transparent;
  border: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: ${({ display }) => display};
  cursor: pointer;

  &:focus,
  &:hover {
    background: ${primaryColors.snowWhite};
  }

  ${({ selected }) => (selected
      && css`
        font-family: NNDagnyDisplay;
        background: ${primaryColors.greyWhite};
      `)
    || ''}
`;

const ListButtonIconWrapper = styled.span`
  position: absolute;
  right: 16px;
`;

export interface IOption<T>{
  value: T;
  option?: string;
}

export interface IOptionsList<T> {
  options: IOption<T>[];
  value?: T;
  onChange: (value: T) => void;
  isOpen?: boolean;
  setIsOpen: (isOpen: boolean) => void;
  dense?: boolean;
}

export function OptionsList<T>({ // eslint-disable-line react/function-component-definition
  isOpen,
  options,
  onChange,
  setIsOpen,
  value,
  dense,
}: IOptionsList<T>): JSX.Element {
  const [display, setDisplay] = useState<'none' | 'block'>('none');

  const selected = (option: IOption<T>) => (option.option === value as unknown as string) || option.value === value; // eslint-disable-line max-len

  return (
    <SelectList
      dense={dense}
      isOpen={isOpen}
      onAnimationEnd={() => !isOpen && setDisplay('none')}
      onAnimationStart={() => isOpen && setDisplay('block')}
    >
      {options.map((option) => (
        <ListButton
          key={`${selected(option)}-${option.option}-${option.value}`}
          display={display}
          onClick={(e): void => {
            e.preventDefault();
            onChange(option.value);
            setIsOpen(false);
          }}
          selected={selected(option)}
        >
          {option.option || option.value}
          {selected(option) && (
            <ListButtonIconWrapper>
              <Pictogram icon="ok" />
            </ListButtonIconWrapper>
          )}
        </ListButton>
      ))}
    </SelectList>
  );
}
