import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import styled from 'styled-components';
import { NavbarElementProps } from 'react-day-picker';
import dayjs from 'dayjs';
import { Input } from '../Input/Input';
import { DateInputNavbar } from './components/DateInputNavbar';
import {
  MONTHS,
  WEEKDAYS_LONG,
  WEEKDAYS_SHORT,
} from './utils/DateInputTranslations';
import { useClickOutsideRef } from '../../hooks';
import { formatDate, parseStringToDate } from '../Format/FormatDate';
import IconCalendar from './calendar.svg';
import { DayPickerStyled } from './components/DayPicker.styled';
import { Pictogram } from '../Pictogram/Pictogram';

export interface DateInputProps {
  label: string;
  value?: Date;
  onChange: (_?: Date) => void;
  disabled?: boolean;
  required?: boolean;
  name?: string;
  error?: string;
  onInValid?: (_: boolean) => void;
  keyPattern?: string;
}

const DateInputWrapper = styled.div``;

const DayPickerWrapper = styled.div<{ isOpen: boolean }>`
  position: relative;
  display: ${({ isOpen }) => (isOpen && 'block') || 'none'};
`;

export const DateInput: React.FC<DateInputProps> = ({
  value,
  label,
  onChange,
  disabled,
  required,
  name,
  error,
  onInValid,
  keyPattern,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [textValue, setTextValue] = useState<string | undefined>(
    value ? formatDate(value) : undefined,
  );
  const [selectedMonth, setSelectedMonth] = useState<Date | undefined>(value);

  const DateInputWrapperRef = useRef<HTMLDivElement>(null);
  const onCloseDatePicker = () => {
    setIsOpen(false);
    setSelectedMonth(undefined);
  };
  useClickOutsideRef(DateInputWrapperRef, () => onCloseDatePicker());

  useEffect(() => {
    setTextValue(value ? formatDate(value) : '');
  }, [value]);

  const onKeyDown = useCallback((): void | string => {
    const textLength = textValue?.length || 0;
    if (textLength === 2 || textLength === 5) {
      setTextValue(`${textValue}.`);
    }
  }, [textValue]);

  const handleOnBlur = () => {
    const parsedDate = parseStringToDate(textValue || '', value);
    const isCorrectDate = !Number.isNaN(parsedDate.getTime());
    if (!isCorrectDate) {
      setTextValue(undefined);
    }
    onChange(isCorrectDate ? parsedDate : undefined);
  };

  const handleOnChangePicker = (newValue: Date) => {
    if (value) {
      onChange(
        dayjs(newValue)
          .set('hour', dayjs(value).get('hour'))
          .set('minutes', dayjs(value).get('minutes'))
          .toDate(),
      );
    } else {
      onChange(newValue);
    }
    onCloseDatePicker();
  };

  const onChangeMonth = useCallback((date: Date) => {
    setIsOpen(true);
    setSelectedMonth(date);
  }, []);

  const DateInputNavbarRender = useCallback(
    (props: NavbarElementProps): JSX.Element => (
      <DateInputNavbar
        month={props.month}
        onNextClick={props.onNextClick}
        onPreviousClick={props.onPreviousClick}
        setSelectedMonth={onChangeMonth}
      />
    ),
    [onChangeMonth],
  );

  return (
    <DateInputWrapper ref={DateInputWrapperRef}>

      <Input
        cursor="pointer"
        disabled={disabled}
        error={error}
        focusOnParent={isOpen}
        icon={(
          <Pictogram
            Icon={IconCalendar}
            iconColor={disabled ? 'greyLight' : 'orangeMedium'}
          />
        )}
        keyPattern={keyPattern || '[0-9]'}
        label={label}
        maxLength={10}
        name={name}
        onBlur={handleOnBlur}
        onChange={(onChangeValue) => setTextValue(onChangeValue)}
        onClick={() => (isOpen ? onCloseDatePicker : setIsOpen(!isOpen))}
        onInValid={onInValid}
        onKeyDown={() => onKeyDown()}
        pattern="\d{2}.\d{2}.\d{4}"
        required={required}
        value={textValue}
      />
      <DayPickerWrapper isOpen={isOpen}>
        <DayPickerStyled
          captionElement={<span />}
          firstDayOfWeek={1}
          initialMonth={value}
          month={selectedMonth || value}
          months={MONTHS}
          navbarElement={DateInputNavbarRender}
          onDayClick={handleOnChangePicker}
          selectedDays={value && [new Date(value)]}
          weekdaysLong={WEEKDAYS_LONG}
          weekdaysShort={WEEKDAYS_SHORT}
        />
      </DayPickerWrapper>
    </DateInputWrapper>
  );
};
